import { CustomTable } from "components/UI";
import CustomCard from "components/UI/CustomCard";
import { formatNumber } from "components/UI/helpers";
import useQuery from "helpers/useQuery";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardTitle, Col, Row } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import moment from "moment";
import { CURRENCY } from "variables";
import { getImageUrl } from "redux/actions/api";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useLocation } from "react-router";
import { EmptyList } from "../Admin/ReportDetails";
import {
  approvePendingPayments,
  _approvePendingPayments,
  rejectPendingPayments,
} from "./api";
import BackButton from "components/UI/BackButton";
import { today } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import { _formatNumber } from "components/UI/helpers";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { CustomButton } from "components/UI";

function ViewPaymentDetails() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  let req_id = query.get("req_id");
  let member_name = query.get("member_name");
  let payment_type = query.get("payment_type");
  const isLoan = payment_type === "LOAN";
  const isWithdrawal = payment_type === "WITHDRAWAL";
  const { society, user } = useSelector((state) => state.auth);
  const isMemberPage = location.pathname.includes(
    "my-pending-payments-details"
  );

  const [requestInfo, setRequestInfo] = useState({
    summary: {
      created_at: today,
    },
    details: [],
    attachments: [],
  });
  const [approving, setApproving] = useState(false);

  const getPendingPaymentRequests = useCallback(() => {
    _fetchApi(
      `/contribution/request-info/${req_id}?society_id=${society.id}&type=${payment_type}`,
      (d) => {
        if (d.success) {
          setRequestInfo(d);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society.id, req_id]);

  useEffect(() => {
    getPendingPaymentRequests();
  }, [getPendingPaymentRequests]);

  const tableFields = [
    { title: "Account Name", value: "account_description" },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {/* {JSON.stringify(item)} */}
          {_formatNumber(isWithdrawal ? item.debit : item.credit)}
        </div>
      ),
    },
  ];

  const handleApproval = () => {
    setApproving(true);
    if (payment_type === "CONTRIBUTION") {
      approvePendingPayments(
        req_id,
        {
          ...requestInfo.summary,
          approved_by: user.id,
          member_id: requestInfo.details[0].member_id,
          requestInfo: requestInfo.details,
        },
        (d) => {
          console.log(d);
          setApproving(false);
          // if (d && d.success) {
          toast.success(d.message);
          history.push("/admin/pending-member-payments");
          // }
          // else {
          //   toast.error(d.message)
          // }
        },
        (e) => {
          console.log(e);
          setApproving(false);
          if (e.success) {
            toast.success(e.message);
            history.push("/admin/pending-member-payments");
          } else {
            toast.error(e.message);
          }
        }
      );
    } else {
      _approvePendingPayments(
        req_id,
        {
          ...requestInfo.summary,
          approved_by: user.id,
          member_id: requestInfo.details[0].member_id,
          requestInfo: requestInfo.details,
          customer: {
            email: user.email,
            name: `${user.firstname} ${user.lastname}`,
          },
        },
        (d) => {
          console.log(d);
          setApproving(false);
          // if (d && d.success) {
          toast.success(d.message);
          history.push("/admin/pending-member-payments");
          // }
          // else {
          //   toast.error(d.message)
          // }
        },
        (e) => {
          console.log(e);
          setApproving(false);
          if (e.success) {
            toast.success(e.message);
            history.push("/admin/pending-member-payments");
          } else {
            toast.error(e.message);
          }
        }
      );
    }
  };
  const handleReject = () => {
    const society_id = society.id;
    rejectPendingPayments(
      society_id,
      { data: requestInfo?.details },
      (d) => {
        console.log(d);
        setApproving(false);
        // if (d && d.success) {
        toast.success(d.message);
        history.push("/admin/pending-member-payments");
        // }
        // else {
        //   toast.error(d.message)
        // }
      },
      (e) => {
        console.log(e);
        setApproving(false);
        if (e.success) {
          toast.success(e.message);
          history.push("/admin/pending-member-payments");
        } else {
          toast.error(e.message);
        }
      }
    );
  };

  return (
    <>
      <BackButton />
      <CustomCard>
        <div className="d-flex flex-direction-row justify-content-between align-items-center">
          <CardTitle className="h3 mb-4 text-center">
            View Payment Information
          </CardTitle>

          <div className="d-flex">
            <CustomButton
              onClick={handleReject}
              color="danger"
              style={{ marginRight: 10 }}
            >
              Reject
            </CustomButton>
            <CustomButton onClick={handleApproval} loading={approving}>
              Approve
            </CustomButton>
          </div>
        </div>
        <Row>
          <Col md={4} className="d-flex flex-direction-row">
            <div className="mr-3">Date:</div>
            <div className="font-weight-bold">
              {requestInfo.summary &&
                requestInfo.summary.created_at &&
                moment(requestInfo.summary.created_at).format("YYYY-MM-DD")}
            </div>
          </Col>
          {isMemberPage ? null : (
            <Col md={4} className="d-flex flex-direction-row">
              <div className="mr-3">Member Name:</div>
              <div className="font-weight-bold">{member_name}</div>
            </Col>
          )}
          <Col md={4} className="d-flex flex-direction-row">
            <div className="mr-3">Total Amount:</div>
            <div className="font-weight-bold">
              {CURRENCY}
              {_formatNumber(requestInfo.summary.total_amount)}
            </div>
          </Col>
          <Col md={4} className="d-flex flex-direction-row align-items-center">
            {/* {JSON.stringify(requestInfo.details)} */}
            <div className="mr-3">Status:</div>
            <StatusBadge status={requestInfo.summary.req_status} />
          </Col>
        </Row>
        <h4>Details</h4>
        <CustomTable fields={tableFields} data={requestInfo.details} />
        <h4>Attachments </h4>
        <Row className="m-0">
          {requestInfo.attachments.map((item, i) => (
            <Card className="col-md-3 p-0">
              <img
                src={getImageUrl(item.file_location)}
                alt={"attachment_" + i}
                className="img-fluid"
              />
            </Card>
          ))}
        </Row>
        {requestInfo.attachments.length ? null : (
          <EmptyList message="No attachment found for this request." />
        )}
        <Toaster top="center" />
      </CustomCard>
    </>
  );
}

export default ViewPaymentDetails;
