import React, { useCallback, useEffect, useState } from "react";
import { CustomForm, CustomButton } from "components/UI";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Alert,
} from "reactstrap";
import cover from "../../../images/2hands.jpg";
import { _postApi } from "redux/actions/api";
import { useHistory, useParams } from "react-router";
import { signup, login } from "redux/actions/auth";
import { useDispatch } from "react-redux";
import { theme, themeClass } from "variables";
import { _fetchApi } from "redux/actions/api";
import { getImageUrl } from "redux/actions/api";
import toast from "react-hot-toast";
import ModalNotification from "components/UI/ModalNotification";
import { ThumbsUp } from "react-feather";
import { primaryColor } from "variables";
import SuccessAlert from "components/UI/SuccessAlert";
import { apiURL } from "variables";
import { formatNumber } from "components/UI/helpers";
import { CURRENCY } from "variables";
import { Link } from "react-router-dom";
import { Checkbox } from "components/UI";
import { _formatNumber } from "components/UI/helpers";

const RegisterForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const initForm = {
    firstname: "",
    lastname: "",
    middlename: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    role: "Member",
    society_id: params.id,
  };
  const [form, setForm] = useState(initForm);
  const [societyInfo, setSocietyInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(localStorage.getItem("@@token"));
  const [successLoading, setSuccessLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChecked = () => {
    setChecked((p) => !p);
  };
  const adminDetails = [
    {
      name: "firstname",
      value: form.firstname,
      required: true,
      type: "text",
      label: "First Name",
      col: 6,
    },
    {
      name: "lastname",
      value: form.lastname,
      required: true,
      type: "text",
      label: "Last Name",
      col: 6,
    },
    {
      name: "phone",
      form: form.phone,
      required: true,
      type: "number",
      label: "Phone number",
      col: 6,
    },
    {
      name: "email",
      form: form.email,
      required: true,
      type: "email",
      label: "Email",
      col: 6,
    },
    {
      name: "address",
      form: form.address,
      type: "textarea",
      label: "Home address",
      col: 6,
    },
    {
      name: "password",
      value: form.password,
      required: true,
      type: "password",
      label: "Password",
      col: 6,
    },
  ];
  const loginFields = [
    {
      name: "email",
      form: form.email,
      type: "email",
      label: "Email",
      col: 12,
    },
    {
      name: "password",
      value: form.password,
      type: "password",
      label: "Password",
      col: 12,
    },
  ];

  const history = useHistory();
  const handleChange = ({ target: { value, name, files } }) => {
    if (name === "profile_pic") {
      let fileLogo = files[0];
      setProfilePic(fileLogo);
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
    // setForm((prev) => ({ ...prev, [name]: value }));
    // if (form.name < 5) {
    //   // alert("hhhh");
    // }
  };

  useEffect(() => {
    _fetchApi(
      "/societies/info/" + params.id,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          setSocietyInfo(resp.data[0]);
        }
      },
      (e) => {
        alert(e.message);
      }
    );
  }, [params.id]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    Object.keys(form).forEach((element, i) => {
      formData.append(element.toString(), form[element]);
    });
    formData.append("profile_pic", profilePic);
    // console.log(formData);
    fetch(`${apiURL}/users/create`, {
      method: "POST",
      body: formData,
    })
      .then((raw) => raw.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setLoading(false);
          setSuccessLoading(true);
          setMessage(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    //   dispatch(
    //     signup(
    //       formData,
    //       (d) => {
    //         setLoading(false);
    //         setForm(initForm);
    //         if (d.success) {
    //           setSuccessLoading(true);
    //           setMessage(d.message);
    //         } else {
    //           toast.error(d.message);
    //           history.push("/auth/login");
    //         }
    //       },
    //       (e) => {
    //         setLoading(false);
    //         setForm(initForm);
    //         toast.error(e.message);
    //       }
    //     )
    //   );
  });
  return (
    <div>
      <Row>
        <Col
          md={6}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
          }}
          className="img-col"
        >
          <div className="title-wrap">
            {/* <h3 className="bits-reg">Register Here</h3> */}
            <h3 className="bits-head">Joli</h3>
            <p>
              {" "}
              Essential Commodities Digital Sales and supply Multi purpose
              cooperative society ltd
            </p>
            <input
              type="submit"
              onClick={() => history.push("/auth/login")}
              className="goto"
              name=""
              value={`Goto Login`}
            />
          </div>
        </Col>
        <Col md={6} className="login-col">
          <div className="mobile-head">
            <h3 className="bits-head">Joli</h3>
            <p className="bits-slo">
              {" "}
              Essential Commodities Digital Sales and supply Multi purpose
              cooperative society ltd
            </p>
          </div>
          <Row className="m-0 p-0">
            <Col md={1}></Col>
            <Col md={10} className="member-col">
              <Card
                className={`${
                  token || checked ? "reg-card1" : "reg-card"
                } shadow-lg p-3`}
              >
                <p className="login-text">Signup as a member</p>
                <hr style={{ padding: 0 }}></hr>
                <div className="text-center">
                  <img
                    src={getImageUrl(societyInfo.society_logo)}
                    // src={cover}
                    className="img-fluid society-logo shadow"
                    alt="society_logo"
                  />
                </div>
                <Row>
                  <Col md={9} style={{ color: "grey", overflow: "ellipsis" }}>
                    <h4 style={{ margin: 0 }}>
                      <span className="font-weight-bold coop-name">
                        {societyInfo.society_name?.length > 60
                          ? `${societyInfo.society_name.substring(0, 60)}...`
                          : societyInfo.society_name}
                      </span>
                    </h4>
                    <p style={{ margin: 0, fontSize: 13 }}>
                      Contact Phone:{" "}
                      <span style={{ color: "rgb(96, 14, 230)" }}>
                        {societyInfo.society_phone}
                      </span>
                    </p>
                    <p style={{ margin: 0, fontSize: 13 }}>
                      Contact Address:
                      <span style={{ color: "rgb(96, 14, 230)" }}>
                        {" "}
                        {societyInfo.society_address}
                      </span>
                    </p>
                    <p style={{ margin: 0, fontSize: 13 }}>
                      Monthly Contribution:
                      <span style={{ color: "rgb(96, 14, 230)" }}>
                        {" "}
                        {CURRENCY}
                        {_formatNumber(societyInfo.contribution)}
                      </span>
                    </p>
                    {token ? (
                      ""
                    ) : (
                      <Row style={{ fontSize: 15 }} className="mt-3 ml-0">
                        <div
                          className="mr-1"
                          style={{ color: "rgb(96, 14, 230)" }}
                        >
                          Already registered with Joli?
                        </div>
                        <Checkbox
                          mb="mb-0"
                          checked={checked}
                          onChange={handleChecked}
                        />
                      </Row>
                    )}
                  </Col>
                  <Col md={3} className="logo-col">
                    <img
                      src={getImageUrl(societyInfo.society_logo)}
                      // src={cover}
                      className="img-fluid societyImage shadow"
                      alt="society_logo"
                    />
                  </Col>
                </Row>
                {token ? (
                  <div>
                    <center className="mt-4">
                      <CustomButton>Join now</CustomButton>
                    </center>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    {checked ? (
                      <div className="mt-5">
                        <LoginOption />
                      </div>
                    ) : (
                      <>
                        <Row className="mt-4">
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                id="First Name"
                                name="firstname"
                                value={form.firstname}
                                onChange={handleChange}
                              />
                              <label
                                for="First Name"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                First Name
                              </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle Name"
                                id="Middle Name"
                                name="middlename"
                                value={form.middlename}
                                onChange={handleChange}
                              />
                              <label
                                for="Last Name"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Middle Name
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                id="Last Name"
                                name="lastname"
                                value={form.lastname}
                                onChange={handleChange}
                              />
                              <label
                                for="Last Name"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Last Name
                              </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="file"
                                className="form-control"
                                placeholder="image"
                                id="profile_pic"
                                name="profile_pic"
                                // value={profilePic}
                                onChange={handleChange}
                              />
                              <label
                                for="profile_pic"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Profile Picture
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                id="Email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                              />
                              <label
                                for="Email"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Email
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="phone"
                                className="form-control"
                                placeholder="Phone Number"
                                id="phone"
                                name="phone"
                                value={form.phone}
                                onChange={handleChange}
                              />
                              <label
                                for="Phone Number"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Phone Number
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            {" "}
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Home Address"
                                id="Home Address"
                                name="address"
                                value={form.address}
                                onChange={handleChange}
                              />
                              <label
                                for="Home Address"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Home Address
                              </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                id="Password"
                                name="password"
                                value={form.password}
                                onChange={handleChange}
                              />
                              <label
                                for="Password"
                                style={{ color: "rgb(160,160,160)" }}
                                className="form-label"
                              >
                                Password
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <div className="text-center">
                      <button className="auth-btn" onClick={handleSubmit}>
                        {loading && <Spinner size="sm" />}
                        {checked ? "Login" : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Card>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
      {/* {JSON.stringify(form)} */}
      {/* <Row>
        <div className="col-md-9 register-right">
          <Row>
            <Col md={12} className="pl-5">
              <h4 className="text-center">Member details</h4>
              <div className="px-4">
                <Card className="px-2">
                  <div className="row">
                    <div className="col-md-8">
                      <h4>
                        You are about to join{" "}
                        <span className="font-weight-bold">
                          {societyInfo.society_name}
                        </span>
                      </h4>
                      <p>Contact Phone: {societyInfo.society_phone}</p>
                      <p>Contact Address: {societyInfo.society_address}</p>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={getImageUrl(societyInfo.society_logo)}
                        className="img-fluid"
                        alt="society_logo"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <CustomForm
                        fields={adminDetails}
                        handleChange={handleChange}
                      />
                      <center>
                        <CustomButton
                          onClick={handleSubmit}
                          className="text-center btn-register"
                          style={theme.primary.btn}
                        >
                          {loading && <Spinner size="sm" color="info" />}
                          Submit
                        </CustomButton>
                      </center>
                    </Form>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row> */}
      <SuccessAlert
        confirm={() => {
          history.push("/auth/login");
        }}
        toggle={() => {
          setSuccessLoading(false);
        }}
        confirmText="Ok"
        message={message}
        successLoading={successLoading}
      />
    </div>
  );
};

const LoginOption = () => {
  return (
    <>
      <div className="form-group" style={{ paddingTop: 7 }}>
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          id="email"
          // value={form.email}
          name="email"
          // onChange={handleChange}
        />
        <label
          for="email"
          style={{ color: "rgb(160,160,160)" }}
          className="form-label"
        >
          Email
        </label>
      </div>
      <div className="form-group">
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          id="password"
          name="password"
          // value={form.password}
          // onChange={handleChange}
        />
        <label
          for="password"
          style={{ color: "rgb(160,160,160)" }}
          className="form-label"
        >
          Password
        </label>
      </div>
    </>
  );
};

export default RegisterForm;
