import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import BackButton from "components/UI/BackButton";
import CustomModal from "components/UI/CustomModal";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { today } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import Loading from "components/UI/Loading";
import StatusBadge from "components/UI/StatusBadge";
import { useQuery } from "hooks";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Alert, Badge, Card, CardTitle, Col, Row } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import { getReports } from "redux/actions/reports";
import { CURRENCY } from "variables";
import { EmptyList } from "../Admin/ReportDetails";
import { approvePendingPayments } from "./api";

function PendingPayments() {
  const query = useQuery();
  const report_type = query.get("report_type");
  // const from = query.get('from')
  const to = query.get("to");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const today = moment().format("YYYY-MM-DD");
  const _from = moment(today).subtract(6, "day").format("YYYY-MM-DD");
  const isMemberPage = location.pathname.includes("my-pending-payments");
  const { society, user } = useSelector((state) => state.auth);

  const [list, setList] = useState([]);
  const [approving, setApproving] = useState(false);
  const [requestInfo, setRequestInfo] = useState({});
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState({
    from: _from,
    to: today,
  });

  const handleDateRangeChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));

  const toggleRequestModal = () => setRequestModalIsOpen((a) => !a);

  const getPendingPaymentRequests = useCallback(() => {
    setLoading(true);
    if (isMemberPage) {
      _fetchApi(
        `/contribution/member-payment-requests/${user.id}/${society.id}?req_status=pending&from=${range.from}&to=${range.to}`,
        (d) => {
          if (d && d.data && d.data.length) {
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          setLoading(false);
          console.log(e);
        }
      );
    } else {
      _fetchApi(
        `/contribution/payment-requests/${society.id}?req_status=pending&from=${range.from}&to=${range.to}`,
        (d) => {
          if (d && d.data && d.data.length) {
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          console.log(e);
          setLoading(false);
        }
      );
    }
    setLoading(false);
  }, [society.id, user.id, isMemberPage, range]);

  useEffect(() => {
    getPendingPaymentRequests();
  }, [getPendingPaymentRequests]);

  // const handleApproval = (item) => {
  //   setApproving(true);
  //   approvePendingPayments(
  //     item.req_id,
  //     { ...item, approved_by: user.id },
  //     (d) => {
  //       setApproving(false);
  //       if (d && d.success) {
  //         toast.success(d.message);
  //         getPendingPaymentRequests();
  //       } else {
  //         toast.error(d.message);
  //       }
  //     },
  //     (e) => {
  //       setApproving(false);
  //       toast.error(e.message);
  //     }
  //   );
  // };

  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
      ),
    },
    { title: "Payment Type", value: "payment_type" },
    { title: "Member Name", value: "member_name", hidden: isMemberPage },
    {
      title: `Total Amount (${CURRENCY})`,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(
            item.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    { title: "Reference", value: "reference" },
    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <StatusBadge status={item.req_status} />
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            onClick={() => {
              if (isMemberPage) {
                history.push(
                  `/member/my-pending-payments-details?req_id=${item.req_id}&member_name=${item.member_name}&payment_type=${item.payment_type}`
                );
              } else {
                history.push(
                  `/admin/pending-member-payments-details?req_id=${item.req_id}&member_name=${item.member_name}&payment_type=${item.payment_type}`
                );
              }
            }}
          >
            View
          </CustomButton>
          {/* {!isMemberPage && (
            <CustomButton
              size="sm"
              loading={approving}
              onClick={() => handleApproval(item)}
            >
              Approve
            </CustomButton>
          )} */}
        </div>
      ),
    },
  ];
  return (
    <>
      <Card body>
        <CardTitle className="h4 text-center mb-2">Pending Payments </CardTitle>
        <DaterangeSelector
          from={range.from}
          to={range.to}
          handleChange={handleDateRangeChange}
        />
        {/* {JSON.stringify(list[0])} */}
        <CustomTable fields={fields} data={list} />
        {list.length ? null : (
          <EmptyList message="There are no pending payments, check back later." />
        )}
        {loading && <Loading />}
        <ViewPaymentRequestInfo
          isOpen={requestModalIsOpen}
          toggle={toggleRequestModal}
          info={requestInfo}
        />
      </Card>
      <Toaster top="center" />
    </>
  );
}

function ViewPaymentRequestInfo({
  isOpen = false,
  toggle = (f) => f,
  info = {},
}) {
  const tableFields = [{ title: "Account Name" }, { title: "Amount" }];
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      header="View Payment Information"
    >
      <Row>
        <Col md={4}>
          <div>Date:</div>
          <div>{info.created_at}</div>
        </Col>
        <Col md={4}>
          <div>Member Name:</div>
          <div>{info.member_name}</div>
        </Col>
        <Col md={4}>
          <div>Total Amount:</div>
          <div>{info.total_amount}</div>
        </Col>
        <Col md={4}>
          <div>Status:</div>
          <div>{info.req_status}</div>
        </Col>

        {/* {JSON.stringify(info)} */}
      </Row>

      <h4>Details</h4>
      <CustomTable fields={tableFields} />
    </CustomModal>
  );
}

export default PendingPayments;
