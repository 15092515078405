import moment from 'moment'
const { _fetchApi } = require('./api')
const { default: types } = require('./types')

export function getDashboardSummary({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
}) {
  return (dispatch) => {
    _fetchApi(
      `/dashboard/summary?query_type=${query_type}&society_id=${society_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          dispatch({
            type: types.dashboard.UPDATE_DASHBOARD_SUMMARY,
            payload: { key: query_type + ' Summary', value: resp.data[0] },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getDashboardGraph({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
}) {
  return (dispatch) => {
    //   alert('getting')
    _fetchApi(
      `/dashboard/graph?query_type=${query_type}&society_id=${society_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          let formattedData = {}
          resp.data.forEach((i) => {
            let f_month = moment(i.month, 'MM').format('MMM')
            if (formattedData.labels) {
              formattedData.labels = [...formattedData.labels, f_month]
              formattedData.series = [[...formattedData.series[0], i.balance]]
            } else {
              formattedData.labels = [f_month]
              formattedData.series = [[i.balance]]
            }
          })
          //   console.log(formattedData)
          dispatch({
            type: types.dashboard.UPDATE_DASHBOARD_GRAPH,
            payload: { key: query_type + ' Graph', value: formattedData },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getReports({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
}) {
  return (dispatch) => {
    _fetchApi(
      `/reports?query_type=${query_type}&society_id=${society_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          dispatch({
            type: types.dashboard.REPORT_BY_TYPE,
            payload: { key: query_type, value: resp.data },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getUserDashboardSummary({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
  user_id,
}) {
  return (dispatch) => {
    _fetchApi(
      `/user-dashboard/summary?query_type=${query_type}&society_id=${society_id}&user_id=${user_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          console.log(resp.data)
          dispatch({
            type: types.dashboard.UPDATE_DASHBOARD_SUMMARY,
            payload: {
              key: query_type + ' Summary',
              value: resp.data[0],
              // value: resp.data === 1 ? resp.data[0] : resp.data,
            },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function _getUserDashboardSummary({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
  user_id,
}) {
  return (dispatch) => {
    _fetchApi(
      `/user-dashboard/summary?query_type=${query_type}&society_id=${society_id}&user_id=${user_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          // console.log(resp.data)
          dispatch({
            type: types.dashboard.UPDATE_DASHBOARD_SUMMARY,
            payload: {
              key: query_type + ' _Summary',
              value: resp.data,
              // value: resp.data === 1 ? resp.data[0] : resp.data,
            },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getUserDashboardGraph({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
  user_id = '',
}) {
  return (dispatch) => {
    //   alert('getting')
    _fetchApi(
      `/user-dashboard/graph?query_type=${query_type}&society_id=${society_id}&user_id=${user_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          let formattedData = {}
          resp.data.forEach((i) => {
            let f_month = moment(i.month, 'MM').format('MMM')
            if (formattedData.labels) {
              formattedData.labels = [...formattedData.labels, f_month]
              formattedData.series = [[...formattedData.series[0], i.balance]]
            } else {
              formattedData.labels = [f_month]
              formattedData.series = [[i.balance]]
            }
          })
          //   console.log(formattedData)
          dispatch({
            type: types.dashboard.UPDATE_DASHBOARD_GRAPH,
            payload: { key: query_type + ' Graph', value: formattedData },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getUserReports({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
  user_id = '',
}) {
  return (dispatch) => {
    _fetchApi(
      `/user-reports?query_type=${query_type}&society_id=${society_id}&user_id=${user_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          dispatch({
            type: types.dashboard.REPORT_BY_TYPE,
            payload: { key: query_type, value: resp.data },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}

export function getLoanDashbord({
  query_type = '',
  society_id = '',
  from = '',
  to = '',
  user_id = '',
}) {
  return (dispatch) => {
    _fetchApi(
      `/loan-dashboard/view?query_type=${query_type}&society_id=${society_id}&user_id=${user_id}&from=${from}&to=${to}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          dispatch({
            type: types.dashboard.LOAN_REPORT,
            payload: { key: query_type, value: resp.data[0] },
          })
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
}
