import useQuery from "helpers/useQuery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  Col,
  Form,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import cover from "../../../images/hands2.jpg";

export default function ResetPassword() {
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => {
    setOpen1(!open1);
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({
    email: "",
    newPassword: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setResetPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    fetch(`${apiURL}/confirm-reset-password?id=${id}`)
      .then((raw) => raw.json())
      .then((resp) => {
        setResetPasswordData({ ...resetPasswordData, email: resp.user.email });
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);
  const submitPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    _postApi(
      "/users/updatepassword",
      resetPasswordData,
      (res) => {
        if (res.success) {
          setLoading(false);
        } else {
          alert(res.msg);
        }
        console.log(res);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    toggle1();
  };
  return (
    <div className="login-div">
      <Row className="m-0 login-row">
        <Col
          md={6}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
          }}
          className="img-col"
        >
          <div className="title-wrap">
            <h3 className="bits-head">BitsCoops</h3>
            <p>Automated cooperative society system</p>
            <input
              type="submit"
              onClick={() => history.push("/auth/login")}
              className="goto"
              name=""
              value={`Goto Log in`}
            />
          </div>
        </Col>
        <Col md={6} className="login-col">
          <div className="mobile-head">
            <h3 className="bits-head">BitsCoops</h3>
            <p className="bits-slo">Automated cooperative society system</p>
          </div>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <div className="login-form">
                {/* <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> */}
                <Form onSubmit={submitPassword}>
                  <Card className="login-card shadow-lg">
                    <CardBody className="">
                      <p className="login-text">Reset Password</p>
                      {/* <p className='m-0 text-secondary' style={{ fontSize: '12px' }}>Enter your email, we will send a link that will help you reset your password</p> */}
                      <hr style={{ padding: 0 }}></hr>
                      <div className="form-group" style={{ paddingTop: 7 }}>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          id="newPassword"
                          value={resetPasswordData.newPassword}
                          name="newPassword"
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="form-group" style={{ paddingTop: 7 }}>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                id="confirmPassword"
                                                value={resetPasswordData.confrimPassword}
                                                name="confirmPassword"
                                                onChange={handleChange}
                                            />
                                            <label
                                                for="confirmPassword"
                                                style={{ color: 'rgb(160,160,160)' }}
                                                className="form-label"
                                            >
                                                Confirm Password
                                            </label>
                                        </div> */}
                      <div className="text-center">
                        <button className="auth-btn">
                          {loading && <Spinner size="sm" />}
                          Change
                        </button>
                        <hr style={{ padding: 0 }}></hr>
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: 5,
                            color: "grey",
                          }}
                        >
                          Already a member?{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              color: "rgb(96, 14, 230)",
                            }}
                            onClick={() => history.push("/auth/login")}
                          >
                            Login here!
                          </span>{" "}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
      <Modal
        size="sm"
        isOpen={open1}
        toggle={toggle1}
        className=""
        style={{ padding: 0 }}
      >
        <ModalBody className="text-center">
          <p className="login-text">Success</p>
          <p className="m-0 text-secondary mt-1" style={{ fontSize: "12px" }}>
            You have successfully created a new password.
          </p>
          <button
            className="auth-btn mt-3"
            onClick={() => history.push("/admin/dashboard")}
          >
            Login
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
}
