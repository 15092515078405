import { CustomButton } from "components/UI";
import DaterangeSelector from "components/UI/DaterangeSelector";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ButtonGroup, Input } from "reactstrap";
import { getRequest } from "./reqHelper";
import { useSelector } from "react-redux";
import { today } from "utils/helpers";
import moment from "moment";
import Loading from "components/UI/Loading";
import { CustomTable } from "components/UI";
import { useQuery } from "hooks";
import { formatNumber } from "utils/helpers";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { _postApi } from "redux/actions/api";
import { _customNotify } from "utils/helpers";
import { _warningNotify } from "utils/helpers";
import BackButton from "components/UI/BackButton";
import { getRoleLink } from "views/helper";
import { postApi } from "redux/actions/api";
import { joliReqInventoryApi } from "redux/actions/api";

export default function ViewRequest() {
  const goto = useHistory();
  const { society, user } = useSelector((state) => state.auth);
  const query = useQuery();
  const req_no = query.get("req_no");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    member_id: user.id,
    req_status: "delivered",
    req_no,
  });

  const handleTable = (name, value, index) => {
    let arr = [];
    data?.forEach((item, i) => {
      if (index === i) {
        arr.push({
          ...item,
          [name]: value,
        });
      } else {
        arr.push(item);
      }
    });
    setData(arr);
  };

  const _getRequest = useCallback(() => {
    getRequest(
      form,
      "select_by_req",
      society.id,
      (d) => setData(d?.data),
      (e) => {
        console.log(e);
        _warningNotify("Error Occur");
        setLoading(false);
      }
    );
  }, [society.id, form]);

  useEffect(() => {
    _getRequest();
  }, [_getRequest]);

  const handleApprove = () => {
    setLoading(true);
    _postApi(
      `/inventory/v1/update-request?society_id=${society.id}`,
      { data },
      (e) => {
        _customNotify("Approved successfully");
        console.log(e);
        setLoading(false);
        goto.push(getRoleLink(`/new-request`));
      },
      (e) => {
        console.log(e);
        _warningNotify("Error Occur");
        setLoading(false);
      }
    );
  };

  const handleDeleivered = () => {
    getRequest(form, "status", society.id, (d) => {
      _customNotify("Deleivered");
      goto.push(getRoleLink(`/new-request`));
    }),
      (e) => {
        _warningNotify("Error Occur");
      };
  };

  const postToJoliApi = () => {
    let arr = [];
    data.forEach((item) =>
      arr.push({
        ...item,
        qty: item.approve_qty,
        branch_name: item.member_name,
        request_at: today,
        req_status: item.req_status ? item.req_status : "pending",
        item_category: item.item_category,
        facilityId: "d8d7a732-1832-4e25-9a98-e68ddc3f0b26",
        payment_status: society.id,
        payment_reference: item.req_no,
        requisition_type: "JOLI Coop. customer",
        request_by: item.member_id,
        item_id: item.item_code,
      })
    );

    postApi(
      joliReqInventoryApi,
      arr,
      () => {
        _customNotify("Successfully post to JOLI for approval");
        setData([]);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const tableFields = [
    {
      title: "S/N",
      custom: true,
      component: (item, i) => <div>{i + 1}</div>,
    },
    {
      title: "Item Name",
      value: "item_name",
    },
    {
      title: "Cost",
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(parseInt(item.cost_price))}
        </div>
      ),
    },
    {
      title: "Request Quantity",
      custom: true,
      component: (item) => (
        <div className="text-right">{formatNumber(parseInt(item.req_qty))}</div>
      ),
    },
    {
      title: "Price",
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(parseInt(item.cost_price * item.req_qty))}
        </div>
      ),
    },
    {
      title: "Approve Quantity",
      custom: true,
      component: (item, i) => (
        <div>
          {item.req_status === "pending" ? (
            <Input
              name="approve_qty"
              value={item.approve_qty}
              onChange={(e) => {
                let value = e.target.value;
                handleTable("approve_qty", value, i);
              }}
            />
          ) : (
            item.approve_qty
          )}
        </div>
      ),
    },
  ];
  const getDataStatus = data ? data[0] : {};
  const total = data.reduce(
    (a, b) => a + parseFloat(b.approve_qty * b.cost_price),
    0
  );
  return (
    <div>
      {loading && <Loading />}
      {/* {JSON.stringify({ data, society })} */}
      <div className="d-flex justify-content-between">
        <BackButton />
        {getDataStatus?.req_status === "pending" ? (
          <CustomButtonAdmin
            onClick={() => {
              handleApprove();
              postToJoliApi();
            }}
          >
            Approve
          </CustomButtonAdmin>
        ) : getDataStatus?.req_status === "approved" ? (
          <CustomButton onClick={handleDeleivered}>
            Approve Delivered
          </CustomButton>
        ) : null}
      </div>
      <div className="text-right">
        Total: <b>{formatNumber(total)}</b>
      </div>
      <CustomTable fields={tableFields} data={data} bordered />
    </div>
  );
}
