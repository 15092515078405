import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { formatNumber } from "utils/helpers";
import { expensesApi } from "../inventory/item-request/reqHelper";
import { AiFillDelete } from "react-icons/ai";
import { _customNotify } from "utils/helpers";

export default function CreateExpenses() {
  const goto = useHistory();
  const { society, user } = useSelector((state) => state.auth);

  const [form, setForm] = useState({
    description: "",
    amount: 0,
    enter_by: `${user.firstname} ${user.lastname}`,
  });
  const [data, setData] = useState([]);

  const handleDelete = (index) => {
    let final = data.filter((it, id) => id !== index);
    setData(final);
  };

  const fields = [
    {
      label: "Description",
      type: "textarea",
      name: "description",
      value: form.description,
      required: true,
      col: 6,
    },
    {
      label: "Amount",
      type: "number",
      name: "amount",
      value: form.amount,
      required: true,
      col: 6,
    },
  ];

  const _fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, i) => <div>{i + 1}</div>,
    },
    {
      title: "Description",
      value: "description",
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-right">{formatNumber(item.amount)}</div>
      ),
    },
    // {
    //   title: "Total",
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-right">
    //       {formatNumber(parseInt(item.selling_price) * parseInt(item.description))}
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      custom: true,
      component: (item, i) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            color={"danger"}
            outline
            onClick={() => handleDelete(i)}
          >
            <AiFillDelete />
          </CustomButton>
        </div>
      ),
    },
  ];
  

  const handleAdd = () => {
    if (form.amount === 0) {
      alert("Invalid form");
    } else {
      setData((p) => [...p, { ...form }]);
      setForm((p) => ({ ...p, description: "", amount: "" }));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleSubmit = () => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      expensesApi(element, "insert", society.id, (e) => console.log(e));
    }
    _customNotify("Expenses recorded");
    goto.push("/admin/expenses");
  };

  const total = data.reduce((it, id) => it + parseInt(id.amount), 0);

  return (
    <div>
      <h4 className="text-center">Create Expenses</h4>
      <CustomForm fields={fields} handleChange={handleChange} />
      <center>
        <CustomButton outline onClick={handleAdd}>
          Add to list
        </CustomButton>
      </center>
      <div className="text-right">
        Total: <b>{formatNumber(total)}</b>
      </div>
      <CustomTable size="sm" bordered fields={_fields} data={data} />
      {data.length && (
        <center>
          <CustomButton outline onClick={handleSubmit}>
            Submit
          </CustomButton>
        </center>
      )}
    </div>
  );
}
