import { CustomButton } from 'components/UI'
import { CustomForm } from 'components/UI'
import BackButton from 'components/UI/BackButton'
import CustomCard from 'components/UI/CustomCard'
import { formatNumber } from 'components/UI/helpers'
import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardTitle } from 'reactstrap'
import { _postApi } from 'redux/actions/api'

function LoanSetupForm() {
  const history = useHistory()

  const [form, setForm] = useState({ finetype: 'Fixed' })
  const [loading, setLoading] = useState(false)

  const society = useSelector((state) => state.auth.society)
  const user = useSelector((state) => state.auth.user)

  const handleChange = ({ target: { name, value } }) =>
    setForm((a) => ({ ...a, [name]: value }))

  const formFields = [
    {
      name: 'type',
      value: form.type,
      label: 'Loan Type',
      type: 'select',
      options: ['Normal', 'Special'],
      required: true,
    },
    {
      name: 'loan_title',
      value: form.loan_title,
      label: 'Loan Title',
      required: true,
    },
    // {
    //   name: 'min_amount',
    //   value: form.min_amount,
    //   label: `Minimum Amount (${formatNumber(form.min_amount)})`,
    //   required: true,
    // },
    {
      name: 'max_amount_type',
      type: 'select',
      options: ['Fixed', 'Percentage'],
      value: form.max_amount_type,
      label: 'Maximum Amount Type',
      required: true,
    },
    {
      name: 'max_amount',
      value: form.max_amount,
      label: `Maximum Amount (${
        form.max_amount_type === 'Percentage'
          ? form.max_amount + '% of savings'
          : formatNumber(form.max_amount)
      })`,
      required: true,
    },
    {
      name: 'interest_rate',
      value: form.interest_rate,
      label: 'Interest Rate',
      required: true,
    },
    {
      name: 'interest_rate_type',
      value: form.interest_rate_type,
      label: 'Interest Rate Type',
      type: 'select',
      options: ['Percentage', 'Fixed'],
      required: true,
    },
    {
      name: 'period_value',
      value: form.period_value,
      label: 'Period',
      required: true,
    },
    {
      name: 'period_duration',
      value: form.period_duration,
      label: 'Period Duration',
      required: true,
      type: 'select',
      options: ['Months'],
      // options: ['Months', 'Years'],
    },
    {
      name: 'finetype',
      value: form.finetype,
      label: `Fine Type`,
      required: true,
      type: 'select',
      options: ['Fixed', 'Percentage'],
      col: 2,
    },
    {
      name: 'admin_charges',
      value: form.admin_charges,
      label: `Fine ${formatNumber(form.admin_charges)}`,
      required: true,
      type: 'number',
      col: 2,
    },
  ]

  const handleSubmit = () => {
    setLoading(true)
    _postApi(
      '/loan-setup/new',
      { ...form, society_id: society.id, created_by: user.id },
      (d) => {
        setLoading(false)
        if (d && d.success) {
          toast.success(d.message)
          history.push('/admin/setup-loan-types')
        } else {
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false)
        toast.error(e.message)
      },
    )
  }

  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4">Setup New Loan</CardTitle>
        <CustomForm fields={formFields} handleChange={handleChange} />
        <center>
          <CustomButton loading={loading} onClick={handleSubmit}>
            Submit
          </CustomButton>
        </center>
      </Card>

      <Toaster />
    </>
  )
}

export default LoanSetupForm
