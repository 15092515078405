import { _postApi } from "redux/actions/api";

const callApi = (
  type = "",
  data = {},
  cb = (f) => f,
  error = (f) => f,
  queryType = "insert"
) => {
  _postApi(
    `/${type}?query_type=${queryType}`,
    data,
    (response) => {
      cb(response);
    },
    (err) => {
      error(err);
    }
  );
};

export function deleteNode(
  type = "",
  node = "",
  cb = (f) => f,
  error = (f) => f
) {
  callApi(type, { parent: node.title }, cb, error, "delete");
}

export function getNextCodeForHead(
  type = "",
  head = "",
  cb = (f) => f,
  error = (f) => f
) {
  callApi(type, { parent: head }, cb, error, "next_code");
}

export function saveNewChart(
  type = "",
  data = [],
  cb = (f) => f,
  error = (f) => f
) {
  callApi(type, data, cb, error, "insert");
}

export function getChartHead(
  type = "",
  cb = (f) => f,
  error = (f) => f
) {
  callApi(type, {}, cb, error, "heads");
}

export function getChart(
  type = "",
  queryType = "chart",
  cb = (f) => f,
  error = {}
) {
  callApi(type, {}, cb, error, queryType);
}

export function updateChart(
  type = "",
  data = [],
  cb = (f) => f,
  error = (f) => f
) {
  callApi(type, data, cb, error, "update");
}
