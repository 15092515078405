import React, { useCallback, useEffect, useState } from 'react'
import { BorderedCard } from 'components/UI'
import { CustomForm, CustomButton } from 'components/UI'
import CustomTable from 'components/UI/CustomTable'
import { useHistory } from 'react-router'
import BackButton from 'components/UI/BackButton'
import {
  Button,
  Card,
  Col,
  Spinner,
  Input,
  FormGroup,
  Label,
  Row,
  CardHeader,
} from 'reactstrap'
import { useQuery } from 'hooks'
import { approveLoan, disburseLoan, getLoanDetails } from './api'
import { useSelector } from 'react-redux'
import { CURRENCY } from 'variables'
import { formatNumber } from 'components/UI/helpers'
import toast, { Toaster } from 'react-hot-toast'
import LoanDetails, { LoanDetailedTable } from '../my-loans/LoanDetails'
import CustomModal from 'components/UI/CustomModal'
import ModalNotification from 'components/UI/ModalNotification'

export default function LoanApproval() {
  const history = useHistory()
  const query = useQuery()

  const loan_id = query.get('loan_id')
  const society = useSelector((state) => state.auth.society)
  const user = useSelector((state) => state.auth.user)

  const [form, setForm] = useState({
    periodFrom: '',
    periodTo: '',
  })
  const [loanInfo, setLoanInfo] = useState({})
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [approvalWarningIsOpen, setApprovalWarningIsOpen] = useState(false)
  const [disbursing, setDisbursing] = useState(false)
  const [disbursementWarningIsOpen, setDisbursementWarningIsOpen] = useState(
    false,
  )

  const openApprovalWarning = () => {
    setApprovalWarningIsOpen(true)
  }

  const getLoanInfo = useCallback(() => {
    getLoanDetails(
      { loan_id, society_id: society.id },
      (d) => {
        if (d && d.data && d.data.length) {
          setLoanInfo(d.data[0])
        }
      },
      (e) => {
        console.log(e)
      },
    )
  }, [loan_id, society.id])

  useEffect(() => getLoanInfo(), [getLoanInfo])

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const disburse = () => {
    setDisbursing(true)
    disburseLoan(
      {
        user_id: user.id,
        loan_id,
        status: 'Approved',
        society_id: society.id,
        notes: form.notes,
        amount:loanInfo.amount
      },
      (d) => {
        setDisbursementWarningIsOpen(false)
        setDisbursing(false)
        if (d && d.success) {
          toast.success(d.message)
          history.push('/admin/loan/application')
        } else {
          toast.error(d.message)
        }
      },
    )
  }

  const approve = () => {
    setApproving(true)
    approveLoan(
      {
        user_id: user.id,
        loan_id,
        status: 'Approved',
        society_id: society.id,
        notes: form.notes,
        amount: loanInfo.amount
      },
      (d) => {
        setApprovalWarningIsOpen(false)
        setApproving(false)
        if (d && d.success) {
          toast.success(d.message)
          history.push('/admin/loan/application')
        } else {
          toast.error(d.message)
        }
      },
      (e) => {
        setApproving(false)
        toast.error(e.message)
      },
    )
  }

  const reject = () => {
    setRejecting(true)
    approveLoan(
      {
        user_id: user.id,
        loan_id,
        status: 'Rejected',
        society_id: society.id,
        notes: form.notes,
      },
      (d) => {
        setRejecting(false)
        if (d && d.success) {
          toast.success(d.message)
          history.push('/admin/loan/application')
        } else {
          toast.error(d.message)
        }
      },
      (e) => {
        setRejecting(false)
        toast.error(e.message)
      },
    )
  }

  return (
    <>
      <BackButton />
      <Card>
        <CardHeader>{`Loan Details (${loan_id})`}</CardHeader>
        <div className="p-2">
          <LoanDetailedTable loan={[loanInfo]} />
          <FormGroup className="mb-4">
            <Label >Remarks</Label>
            <Input
              className="form-control-alternative"
              cols="80"
              name="notes"
              placeholder="Rejection Remarks..."
              rows="4"
              type="textarea"
              value={form.notes}
              onChange={handleChange}
            />
          </FormGroup>
          <center>
            <CustomButton
              className="px-5 mx-1"
              onClick={openApprovalWarning}
              loading={approving}
            >
              Approve
            </CustomButton>
            <CustomButton
              className="px-5 mx-1"
              onClick={openApprovalWarning}
              loading={approving}
              color="info"
            >
              Approve & Disburse
            </CustomButton>
            <CustomButton color="danger" className="px-5 mx-1" onClick={reject}>
              {rejecting && <Spinner />}
              Reject
            </CustomButton>
          </center>
        </div>
      </Card>

      <ModalNotification
        isOpen={approvalWarningIsOpen}
        confirm={approve}
        confirmText="Approve"
        confirmLoading={approving}
        toggle={() => setApprovalWarningIsOpen((p) => !p)}
        // footer={
        //   <div className='d-flex flex-direction-row justify-content-end bg-danger'>
        //     <CustomButton onClick={() => setApprovalWarningIsOpen(false)}>
        //       Cancel
        //     </CustomButton>
        //     <CustomButton onClick={approve}>Approve</CustomButton>
        //   </div>
        // }
      >
        <h3 className="text-center">
          Are you sure you want to approve this loan?
        </h3>
      </ModalNotification>
      <ModalNotification
        isOpen={disbursementWarningIsOpen}
        confirm={disburse}
        confirmText="Disburse"
        confirmLoading={disbursing}
        toggle={() => setDisbursementWarningIsOpen((p) => !p)}
      >
        <h3 className="text-center">
          Are you sure you want to approve and disburse this loan now?
        </h3>
      </ModalNotification>
    </>
  )
}
