import { useEffect } from "react";
import {
  Card,
  Navbar,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import { getUserDashboardSummary } from "redux/actions/reports";
import { getDashboardSummary } from "redux/actions/reports";
import { theme } from "variables";
import { CURRENCY } from "variables";
import { formatNumber, _formatNumber } from "./helpers";
import './style.css'

export default function CustomLoanStatCardAdmin({
  title,
  action = (f) => f,
  range = {},
  _title='',
  card_class = "",
  card_font = "",
  onDefaultersClick = (f) => f,
  defaultText = "",
  numberOfDefaulter = 0,
}) {
  const location = useLocation();
  const isMembersDashboard =
    location.pathname.includes("/member-dashboard") ||
    location.pathname.includes("/member/dashboard");
  const dispatch = useDispatch();
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);
  const reports =
    useSelector((state) => state.reports)[title + " Summary"] || {};

  useEffect(() => {
    if (isMembersDashboard) {
      dispatch(
        getUserDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user.id,
        })
      );
    } else {
      dispatch(
        getDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
        })
      );
    }
  }, [society.id, dispatch, range.from, range.to]);

  return (
    <Col
      lg="4"
      sm="8"
      onClick={() => action()}
      // variant={`outline-${card_class}`}
      //
    >
      <Card className="card-stats border-primary-custom">
        {/* {JSON.stringify(reports)} */}
        <Card.Body style={{
          borderRadius: 5,
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        }}>
          <div onClick={() => action()} style={{ cursor: "pointer",borderBottom: "1px solid #e6e6e6",width: "100%" }}>
            <Row>
              <Col xs={12}>
                <Card.Title
                  as="h5"
                  className={` mb-2`}
                  style={{ 
                    color: theme.primary.main.color, 
                    marginLeft: 138,
                    marginTop: 10,
                    letterSpacing: 1
                  }}
                >
                  {_title?_title:title}
                </Card.Title>
              </Col>
            </Row>
            <Row className="">
              <Col md={3} className="text-left   pr-2">
                <i
                  className={`${card_font}  pr-2`}
                  style={{ 
                    fontSize: "30px", 
                    color: "#fff", 
                    backgroundColor: '#600EE6', 
                    width: 60, padding: 4, 
                    paddingLeft: 6, 
                    paddingTop: 10, 
                    marginTop: -12,
                    marginLeft: 30,
                    borderRadius: 15,
                    height: 50,
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                  }}
                ></i>
              </Col>
              <Col md={9} xs="9">
                <div>
                  <span className="text-muted" style={{ fontSize: 24, marginLeft: 37 }}>
                    {CURRENCY} {_formatNumber(reports.balance )}
                  </span>
                  {isMembersDashboard ? (
                    <div className="my-3" />
                  ) : (
                    <p
                      className={` text-sm`}
                      style={{ color: theme.primary.main.color, marginLeft: 37 }}
                    >
                      <span className="">
                        {formatNumber(reports.members)} Member(s)
                      </span>
                    </p>
                  )}
                </div>
                <Row></Row>
                {/* {data.map((d, i) => (
                <Row key={i}>
                  <p className={`text-muted text-sm text-${card_class}`}>
                    <span className="">{data[i].key}</span>:
                    <span className="text-muted">{data[i].val}</span>{' '}
                  </p>
                </Row>
              ))} */}
              </Col>
            </Row>
          </div>
          {/* <Row>
            <Col xs={12}>
              <Card.Title
                as="h5"
                className={` mb-2`}
                style={{ color: theme.primary.main.color }}
              >
                Defaulters
              </Card.Title>
            </Col>
          </Row> */}

          {/* members sections */}
          <div onClick={onDefaultersClick} style={{ cursor: "pointer" }}>
            {/* <Row className="mt-4">
              <Col xs={12}>
                <Card.Title
                  as="h5"
                  className={` mb-2`}
                  style={{ color: theme.primary.main.color }}
                >
                  Defaulters
                </Card.Title>
              </Col>
            </Row> */}
            <Row className="mt-1">
              <Col md={3} xs="3"></Col>
              <Col md={9} xs="9">
                {/* <p
                className={` text-sm my-2`}
                style={{ color: theme.primary.main.color }}
              >
                <span className="">Defaulters</span>
              </p> */}

                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className='hrefer'
                  style={{
                    color: theme.primary.main.backgroundColor,
                    marginLeft: 37
                  }}
                >
                  {/* {reports.members ? formatNumber(numberOfDefaulter) : ""}{" "} */}
                  {defaultText}
                </a>

                <Row className="mt-2"></Row>
                {/* {data.map((d, i) => (
                <Row key={i}>
                  <p className={`text-muted text-sm text-${card_class}`}>
                    <span className="">{data[i].key}</span>:
                    <span className="text-muted">{data[i].val}</span>{' '}
                  </p>
                </Row>
              ))} */}
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
