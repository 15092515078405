import Membershipaplication from "views/app/membership/Membership_Application";
import PostingTable from "views/app/postingSaving/PostingTable";
import LoanDisbursement from "views/app/loanApplication/LoanDisbursement";
import ItemListTable from "views/app/item/ItemListTable";
import LoanApplication from "views/app/loanApplication/PendingLoans";
import MemberDetails from "views/app/ViewMemberDetails/MemberDetails";
import Withdrawal from "views/app/withdraw/Withdrawal";
import ItemIssueForm from "views/app/itemIssue/ItemIssueForm";
import ContributionPosting from "views/app/monthlyContribution/ContributionPosting";
4;
// Extras
import Membership from "views/app/membership/Membership";
import Share from "views/app/membership/Total_Share";
import Loan from "views/app/membership/Pending_loan";
import Component from "views/app/membership/Component";
import PostingForm from "views/app/postingSaving/PostingForm";
// import PostingTable from "views/app/postingSaving/PostingTable";
import PostSpecialTable from "views/app/postSpecial/PostSpecialTable";
import PostLoanForm from "views/app/postLoanRepayment/PostLoanForm";
import PostLoanTable from "views/app/postLoanRepayment/PostLoanTable";
// import LoanApplication from "views/app/loanApplication/PendingLoans";
import ViewPost from "views/app/postingSaving/ViewPost";
// import ItemListTable from "views/app/item/ItemListTable";
import ItemList from "views/app/item/ItemList";
// import ItemIssueForm from "views/app/itemIssue/ItemIssueForm";
import ItemIssueTable from "views/app/itemIssue/ItemIssueTable";
// import MemberDetails from "views/app/ViewMemberDetails/MemberDetails";
// import Withdrawal from "views/app/withdraw/Withdrawal";
import WithdrawalTable from "views/app/withdraw/WithdrawalTable";
// import TargetSvingform from "views/app/targetSving/TargetSvingform";
// import TargetSvingTable from "views/app/targetSving/TargetSavingTable";
import PostSpecialForm from "views/app/postSpecial/postSpecialForm";
import AdminRoute from "views/app/AdminRoute";
// import ContributionPosting from "views/app/monthlyContribution/ContributionPosting";
import ContributionTable from "views/app/monthlyContribution/ContributionTable";
// import LoanDisbursement from "views/app/loanApplication/LoanDisbursement";
import LoanDisbursementForm from "views/app/loanApplication/LoanDisbursementForm";
import LoanApproval from "views/app/loanApplication/LoanApproval";
import LoanRepayment from "views/app/loanApplication/LoanRepayment";
import LoanRepaymentPosting from "views/app/loanApplication/LoanRepaymentPosting";
import Special from "views/app/membership/Special_saving";

import View from "views/app/membership/View_members";
import Total from "views/app/membership/Total_saving";
import Dashboard from "views/MemberDashboard";
import MyLoans from "views/app/my-loans";
import NewPayments from "views/app/monthlyContribution/NewPayments";
import LoanDetails from "views/app/my-loans/LoanDetails";
import NewLoanApplication from "views/app/my-loans/NewLoanApplication";
import TransactionHistory from "views/app/transaction-history";
import LoanRepaymentConfirmation from "views/app/loanApplication/LoanRepaymentConfirmation";
import ReportDetails from "views/app/Admin/ReportDetails";
import PendingPayments from "views/app/monthlyContribution/PendingPayments";
import PaymentConfirmationPage from "views/app/monthlyContribution/PaymentConfirmationPage";
import ViewPaymentDetails from "views/app/monthlyContribution/ViewPaymentDetails";
import MyWithdrawals from "views/app/withdraw/MyWithdrawals";
import Profile from "views/examples/Profile";
import GrantorList from "views/app/grantor";
import AccountSwitch from "views/examples/AccountSwitch";
import {
  MdDashboard,
  MdOutlinePayments,
  MdSavings,
  MdLocalAtm,
} from "react-icons/md";
import { RiHandCoinFill } from "react-icons/ri";
import { GiAsianLantern, GiMedallist, GiReceiveMoney } from "react-icons/gi";
import { AiOutlineTransaction } from "react-icons/ai";
import ViewRequest from "views/app/inventory/item-request/ViewRequest";
import Request from "views/app/inventory/item-request/Request";
import ItemRequest from "views/app/inventory/item-request/ItemRequest";

const SideBarData = [
  {
    // title: 'Membership',
    subItem: [
      {
        path: "/dashboard",
        icon: (
          <MdDashboard
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        name: "Dashboard",
        component: Dashboard,
        layout: "/member",
      },
      {
        path: "/report-details",
        // icon: "nc-icon nc-alien-33",
        component: ReportDetails,
        layout: "/member",
      },
      // {
      //   path:"/members",
      //   name:"Members",
      //   component:Membershipaplication,
      //   layout:"/member",
      // },
      // {
      //   path: '/member-payments',
      //   name: 'Savings',
      //   component: NewPayments,
      //   layout: '/member',
      // },
      {
        path: "/member-payments",
        name: "Savings",
        icon: (
          <MdSavings
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: PaymentConfirmationPage,
        layout: "/member",
      },
      {
        path: "/loan/my-loans",
        name: "My Loans",
        icon: (
          <RiHandCoinFill
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: MyLoans,
        layout: "/member",
      },
      {
        path: "/loan/grantor-request",
        name: "Guarantor Request",
        icon: (
          <GiMedallist
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: GrantorList,
        layout: "/member",
      },
      {
        path: "/my-pending-payments",
        name: "My Pending Payments",
        icon: (
          <MdOutlinePayments
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: PendingPayments,
        layout: "/member",
      },
      {
        path: "/my-pending-payments-details",
        component: ViewPaymentDetails,
        layout: "/member",
      },
      {
        path: "/loan/new-loan-application",
        component: NewLoanApplication,
        layout: "/member",
      },

      {
        path: "/member-payment-confirmation",
        component: PaymentConfirmationPage,
        layout: "/member",
      },
      {
        path: "/loan-repayment",
        name: "Loan Repayment",
        icon: (
          <GiReceiveMoney
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: LoanRepayment,
        layout: "/member",
      },
      {
        path: "/loan/my-loan-details",
        component: LoanDetails,
        layout: "/member",
      },
      {
        path: "/loan-repayment-confirm",
        component: LoanRepaymentConfirmation,
        layout: "/member",
      },
      // {
      //   path:"/item/list",
      //   name:"Item List",
      //   component:ItemListTable,
      //   layout:"/member",
      // },
      // {
      //   path:"/item/issue",
      //   name:"Item Issue",
      //   component:ItemIssueForm,
      //   layout:"/member",
      // },
      // {
      //   path:"/loan/application",
      //   name:"Loan Applications",
      //   component:LoanApplication,
      //   layout:"/member",
      // },
      // {
      //   path:"/view-member-details",
      //   name:"Membership groups",
      //   component:MemberDetails,
      //   layout:"/member",
      // },
      {
        path: "/withdraw",
        name: "My Withdrawals",
        icon: (
          <MdLocalAtm
            style={{
              fontSize: 19,
              marginTop: -3,
              marginRight: -20,
              color: "#fff",
            }}
          />
        ),
        component: MyWithdrawals,
        layout: "/member",
      },
      {
        path: "/new-withdraw",
        component: Withdrawal,
        layout: "/member",
      },
      {
        path: "/transaction-history",
        name: "My Transactions",
        icon: (
          <AiOutlineTransaction
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: TransactionHistory,
        layout: "/member",
      },
      {
        path: "/user-profile",
        // name: 'My Profile',
        component: Profile,
        layout: "/member",
      },

      {
        path: "/new-request",
        name: "New Request",
        component: Request,
        layout: "/member",
        icon: (
          <GiAsianLantern
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
      },
      {
        path: "/view-request",
        component: ViewRequest,
        layout: "/member",
      },
      {
        path: "/new-requests/new",
        component: ItemRequest,
        layout: "/member",
      },
      // {
      //   path:"/target-saving",
      //   name:"Target Savings Form",
      //   layout:"/member",
      // },
      // {
      //   path:"/loan-disbursement",
      //   name:"Loan Disbursement (Treasury)",
      //   layout:"/member",
      // },
    ],
  },

  // {
  //   title: 'Others',
  //   subItem: [
  //     {
  //       path: '/membership/specialsaving',
  //       component: Special,
  //       layout: '/member',
  //     },
  //
  //     {
  //       path: '/loan/new-loan-application',
  //       component: NewLoanApplication,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/membership/total_shares',
  //       component: Share,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/membership/pending_loan',
  //       component: Loan,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/membership/view/:id',
  //       component: View,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/membership/totalsaving',
  //       component: Total,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/membership/componenet',
  //       component: Component,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/members-saving',
  //       component: PostingTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan_disbursement_form',
  //       component: LoanDisbursementForm,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/members-shares',
  //       component: PostingTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan-disbursement',
  //       component: LoanDisbursement,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/posting-new:type?',
  //       component: PostingForm,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/members/:type?',
  //       component: PostSpecialTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/post/special-new',
  //       component: PostSpecialForm,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/post/repayment',
  //       component: PostLoanTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/post/repayment-new',
  //       component: PostLoanForm,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan/application',
  //       component: LoanApplication,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan_approval',
  //       component: LoanApproval,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan-repayment',
  //       component: LoanRepayment,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan-repayment-confirm',
  //       component: LoanRepaymentConfirmation,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/loan_repayment_posting',
  //       component: LoanRepaymentPosting,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/view-post/:member_code?',
  //       component: ViewPost,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/item/list',
  //       component: ItemListTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/item/list/new/:id?',
  //       component: ItemList,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/item/issue-new',
  //       component: ItemIssueForm,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/item/issue',
  //       component: ItemIssueTable,
  //       layout: '/member',
  //     },

  //     {
  //       path: '/withdraw',
  //       component: WithdrawalTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/contribution-new',
  //       component: ContributionPosting,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/contribution',
  //       component: ContributionTable,
  //       layout: '/member',
  //     },
  //     {
  //       path: '/targ',
  //       component: ContributionTable,
  //       layout: '/member',
  //     },
  //   ],
  // },
];
export default SideBarData;
