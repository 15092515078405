import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTransactionHistory } from "./api";
import moment from "moment";
import { Card, CardTitle } from "reactstrap";
import { CustomTable } from "components/UI";
import { SelectInput } from "components/UI";
import { formatNumber } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import MemberListInput from "../utilities/MemberListInput";

function TransactionHistory() {
  const today = moment().format("YYYY-MM-DD");
  const aYearAgo = moment(today).subtract(1, "year").format("YYYY-MM-DD");
  // const society = useSelector((state) => state.auth.society);
  const {
    auth: { user, society, societies },
    society: { members },
  } = useSelector((s) => s);

  const [list, setList] = useState([]);
  const [form, setForm] = useState({ user_id: user.id });
  const [selectMembers, setSelectMembers] = useState({});
  const [selectDec, setSelectDec] = useState([]);
  const getHistory = useCallback(() => {
    getTransactionHistory(
      {
        society_id: society.id,
        user_id: selectMembers.id,
        from: aYearAgo,
        to: today,
      },
      (d) => {
        setList(d.data);
        const desc = d?.data?.map((e) => e?.account_description);
        var unique = desc.filter(
          (value, index, array) => array.indexOf(value) === index
        );
        setSelectDec(unique);
      }
    );
  }, [society, selectMembers.id, today, aYearAgo]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  useEffect(() => {
    if (!Object.keys(selectMembers).length) {
      setSelectMembers({ ...user, user_id: user.id });
    }
  }, [form.user_id]);

  const tableFields = [
    {
      title: "Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Name", value: "member_name" },
    { title: "Description", value: "account_description" },
    {
      title: "Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.credit.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <StatusBadge status={i.status} />
        </div>
      ),
    },
  ];

  const rows = [];
  list?.forEach((p) => {
    if (
      p.account_description &&
      p.account_description
        .toLowerCase()
        .indexOf(form.account_description?.toLowerCase()) === -1
    )
      return;
    else rows.push(p);
  });
  const data = form.account_description ? rows : list;
  const total =
    data && data.reduce((item, id) => item + parseFloat(id.credit), 0);
  return (
    <>
      {/* {JSON.stringify(society)} */}
      <Card body>
        <CardTitle className="text-center h4 mb-2">
          <div>
            Transaction History of{" "}
            {`${selectMembers.firstname} ${selectMembers.lastname}`}
          </div>
        </CardTitle>
        <div className="d-flex justify-content-between">
          <SelectInput
            label="Filter by account description"
            placeholder="Account Description"
            options={selectDec || []}
            onChange={(e) =>
              setForm((p) => ({ ...p, account_description: e.target.value }))
            }
            value={form.account_description}
            name="account_description"
            container="col-md-6"
          />

          {society.role === "Admin" && (
            <MemberListInput
              value={form.user_id}
              onChange={(e) => {
                let value = e.target.value;
                const selected = members.find((e) => e.user_id === value);

                setForm((p) => ({ ...p, user_id: value }));
                setSelectMembers({ ...selected, id: selected.user_id });
              }}
              container="col-md-6"
            />
          )}
        </div>
        <div className="text-right">
          Total: <b>{formatNumber(total)}</b>
        </div>
        <CustomTable fields={tableFields} data={data || []} />
      </Card>
    </>
  );
}

export default TransactionHistory;
