import React, { useCallback, useEffect, useState } from "react";
import { BorderedCard } from "components/UI";
import Col from "reactstrap/lib/Col";
import { CustomForm, CustomButton } from "components/UI";
import Row from "reactstrap/lib/Row";
import { useHistory } from "react-router";
import { Card } from "reactstrap";
import { useQuery } from "hooks";
import { useSelector } from "react-redux";
import { CURRENCY } from "variables";
import { formatNumber } from "components/UI/helpers";
import { CustomTable } from "components/UI";
import RepaymentSchedule from "./RepaymentSchedule";
import { disburseLoan, getLoanDetails } from "./api";
import toast, { Toaster } from "react-hot-toast";
import ModalNotification from "components/UI/ModalNotification";
import { _formatNumber } from "components/UI/helpers";

export default function LoanDisbursementForm() {
  const history = useHistory();
  const query = useQuery();

  const loan_id = query.get("loan_id");
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);

  const [form, setForm] = useState({
    bankName: "",
    accountNo: "",
    amount: "",
  });

  const [loanInfo, setLoanInfo] = useState({});
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [disbursementWarningIsOpen, setDisbursemetWarningIsOpen] =
    useState(false);

  const getLoanInfo = useCallback(() => {
    getLoanDetails(
      { loan_id, society_id: society.id },
      (d) => {
        if (d && d.data && d.data.length) {
          setLoanInfo({
            ...d.data[0],
            total_interest:parseFloat(d.data[0].total_interest).toFixed(2),
            amount_disbursed: 
              parseFloat(d.data[0].amount).toFixed(2) -
              parseFloat(d.data[0].total_interest).toFixed(2),
          })
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [loan_id, society.id]);

  useEffect(() => getLoanInfo(), [getLoanInfo]);

  const fields = [
    {
      name: "bank_name",
      label: "Bank Name",
      type: "text",
      value: loanInfo.bank_name,
      col: 6,
    },
    {
      name: "account_no",
      label: "Account No",
      type: "text",
      value: loanInfo.account_no,
      col: 6,
    },
    {
      name: "account_name",
      label: "Account Name",
      type: "text",
      value: loanInfo.account_name,
      col: 6,
    },
    {
      name: "amount_disbursed",
      label: `Amount Disbursed (${_formatNumber(loanInfo.amount_disbursed)})`,
      type: "text",
      value: loanInfo.amount_disbursed,
      col: 6,
    },
  ];

  const handleChange = ({ target: { value, name } }) => {
    setLoanInfo((prev) => ({ ...prev, [name]: value }));
  };

  //   const tablefields = [
  //     { title: 'Loan Type', value: 'loan_type' },
  //     { title: 'Amount...', value: 'amount' },
  //     { title: 'Amount Pending', value: 'amount_pending' },
  //     { title: 'Balance', value: 'balance' },
  //     { title: 'Period', value: 'period' },
  //   ]
  //   const tableData = [
  //     {
  //       loan_type: 'Simple Loan',
  //       amount: '1212',
  //       amount_pending: '1212',
  //       balance: '1212',
  //       period: '1212',
  //       amount: '100',
  //     },
  //   ]

  const approve = () => {
    setApproving(true);
    disburseLoan(
      {
        user_id: user.id,
        member_id: loanInfo.member_id,
        loan_id,
        account_head: loanInfo.account_head,
        status: "Disbursed",
        society_id: society.id,
        period_value: loanInfo.period_value,
        period_duration: loanInfo.period_duration,
        total_interest:parseFloat(loanInfo.total_interest).toFixed(2),
        amount: parseFloat(loanInfo.amount_disbursed).toFixed(2),
        act_amount:parseFloat(loanInfo.amount).toFixed(2),
        account_description: loanInfo.loan_title,
        admin_charges: parseFloat(loanInfo.total_admin_charges).toFixed(),
        loan_setup_id:loanInfo.loan_setup_id
      },
      (d) => {
        setDisbursemetWarningIsOpen(false);
        setApproving(false);
        if (d && d.success) {
          toast.success(d.message);
          history.push("/admin/loan-disbursement");
        } else {
          toast.error(d.message);
        }
      },
      (e) => {
        setApproving(false);
        toast.error(e.message);
      }
    );
  };

  const reject = () => {};

  return (
    <>
      <Card title="Loan Disbursement" body>
        {/* {JSON.stringify(loanInfo)} */}
        <div className="p-2">
          <Row>
            <Col md="4">
              
              {/* <p>File No: 1212121</p> */}
              <p>
                Applicant Name: <p>{loanInfo.member_name}</p>
              </p>
            </Col>
            <Col md="4">
              <p>Loan No: {loanInfo.loan_id}</p>
              <p>
                Loan Amount: {CURRENCY} {_formatNumber(loanInfo.amount)}
              </p>
            </Col>
            <Col md="4">
              <p>Loan Type: {loanInfo.loan_type}</p>

              <p>
                Total Interest: {CURRENCY}{" "}
                {_formatNumber(loanInfo.total_interest)}
              </p>
            </Col>
          </Row>
    {/* {JSON.stringify(loanInfo)} */}
          {/* <CustomTable fields={tablefields} data={tableData} size="sm" /> */}
          {/* <RepaymentSchedule /> */}
          
        </div>
        <CustomForm fields={fields} handleChange={handleChange} />
        <center>
          <CustomButton
            className="px-5"
            onClick={() => setDisbursemetWarningIsOpen(true)}
            loading={approving}
          >
            Disburse funds now
          </CustomButton>
          {/* <Button color="danger" className="px-5" onClick={reject}>
              {rejecting && <Spinner />}
              REJECT
            </Button> */}
        </center>
      </Card>

      <ModalNotification
        isOpen={disbursementWarningIsOpen}
        confirm={approve}
        confirmLoading={approving}
        confirmText="Disburse now"
        toggle={() => setDisbursemetWarningIsOpen((p) => !p)}
        // footer={
        //   <div className='d-flex flex-direction-row justify-content-end bg-danger'>
        //     <CustomButton onClick={() => setApprovalWarningIsOpen(false)}>
        //       Cancel
        //     </CustomButton>
        //     <CustomButton onClick={approve}>Approve</CustomButton>
        //   </div>
        // }
      >
        <h3 className="text-center">
          Are you sure you want to disburse funds?
        </h3>
      </ModalNotification>
    </>
  );
}
