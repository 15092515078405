import CustomTree from "components/UI/CustomTree";
import React from "react";

function StructureTree({
  editNode = (f) => f,
  deleteNode = (f) => f,
  addChild = (f) => f,
  treeData = [],
}) {
  return (
    <CustomTree
      generateNodeProps={({ node, path }) => ({
        title: `${node?.title} - ${node?.description}`,
        buttons: [
          <button
            className="btn btn-primary btn-sm mr-1"
            onClick={() => addChild(node)}
          >
            Add
          </button>,
          <button
            className="btn btn-success btn-sm mr-1"
            onClick={() => editNode(node)}
          >
            Edit
          </button>,
          !node?.children?.length && (
            <button
              className="btn btn-danger btn-sm ml-1"
              onClick={() => deleteNode(node)}
            >
              Delete
            </button>
          ),
        ],
        // style: { backgroundColor:'red'}
      })}
      treeData={treeData}
    />
  );
}

export default StructureTree;
