import { SearchBar } from "components/UI";
import { CustomButton } from "components/UI";
import CustomTable from "components/UI/CustomTable";
import { formatNumber } from "components/UI/helpers";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardBody, Row, Col } from "reactstrap";
import { api_url } from "redux/actions";
import { _fetchApi } from "redux/actions/api";
import { themeClass } from "variables";
import { getRoleLink } from "views/helper";
import moment from "moment";
import { getDashboardSummary } from "redux/actions/reports";
import { getUserDashboardSummary } from "redux/actions/reports";
import { CURRENCY } from "variables";
import { primaryText } from "variables";
import { theme } from "variables";
import { getLoanDashbord } from "redux/actions/reports";
import { _formatNumber } from "components/UI/helpers";

function MyWithdrawals() {
  const today = moment().format("YYYY-MM-DD");
  const startOfYear = moment().startOf("y").format("YYYY-MM-DD");
  const [range, setRange] = useState({ from: startOfYear, to: today });
  const [results, setResults] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);
  const title = "Special contribution";
  const reports =
    useSelector((state) => state.reports)[title + " Summary"] || {};

  const isMembersDashboard = true;

  useEffect(() => {
    if (isMembersDashboard) {
      dispatch(
        getUserDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user.id,
        })
      );
    } else {
      dispatch(
        getDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
        })
      );
    }
  }, [society.id, dispatch, range.from, range.to]);

  const fields = [
    {
      title: "Date",
      value: "date",
    },
    {
      title: "Member",
      value: "member",
    },
    {
      title: "Amount Request",
      component: (item) => (
        <div className="text-right">{formatNumber(item.amount_request)}</div>
      ),
    },
    {
      title: "Special Saving Balance",
      component: (item) => (
        <div className="text-right">{formatNumber(item.debit)}</div>
      ),
    },
    {
      title: "Total Balance",
      component: (item) => (
        <div className="text-right">{formatNumber(item.balance)}</div>
      ),
    },
  ];

  const getListWithdraw = () => {
    _fetchApi(`${api_url}/withdrawal-report`, (resp) => {
      if (resp.success) {
        setResults(resp.data);
        // alert("success");
      }
    });
  };

  useEffect(() => {
    getListWithdraw();
  }, []);

  return (
    <div>
      <Card body>
        <Row className="m-0">
          <SummaryItem
            label="Total Withdrawal"
            value={reports.total_withdrawal}
          />
          <SummaryItem
            label="Withdrawal for the month"
            value={reports.total_withdrawal}
          />
          <SummaryItem label="Current Balance" value={reports.balance} />
        </Row>

        <CardBody>
          <CustomButton
            className="px-6 mb-3"
            onClick={() => history.push(getRoleLink(`/new-withdraw`))}
          >
            New Withdrawal Request
          </CustomButton>

          <SearchBar placeholder="Search for a member" />
          {/* {JSON.stringify(reports)} */}
          <CustomTable fields={fields} data={results} size="sm" />
        </CardBody>
      </Card>
    </div>
  );
}

export function SummaryItem({
  label = "",
  value = "",
  society = {},
  range = {},
  user = {},
  _label=''
}) {
  const dispatch = useDispatch();
  const _getLoanDashbord = useCallback(
    () => [
      dispatch(
        getLoanDashbord({
          query_type: label,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user?.id,
        })
      ),
    ],
    [dispatch]
  );
  useEffect(() => {
    _getLoanDashbord();
  }, [_getLoanDashbord]);
  return (
    <Col>
      <Card
        className="d-flex flex-direction-row justify-content-center align-items-center"
        body
        style={{
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        }}
      >
        <h5 style={{ color: theme.primary.main.color,letterSpacing: 1 }}>{_label?_label:label}</h5>
        <h4
          style={{ color: theme.primary.main.color }}
          className="mt-0 pt-0 font-weight-bold"
        >
          {CURRENCY}
          {_formatNumber(value)}
        </h4>
      </Card>
    </Col>
  );
}

export default MyWithdrawals;
