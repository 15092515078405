import React, { useCallback, useState } from "react";
import { CustomForm, CustomButton } from "components/UI";
import { Card, CardBody, Row, Col, Button, Form, Spinner } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { login } from "redux/actions/auth";
import "assets/css/register.css";
import { themeClass } from "variables";
import { primaryText } from "variables";
import { apiURL } from "variables";

const Signup = () => {
  const dispatch = useDispatch();
  const initForm = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    role: "Admin",
    society_type: "Association",
    society_address: "",
    society_phone: "",
    society_email: "",
    form_type: "Signup",
  };
  const [form, setForm] = useState(initForm);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);

  const fields = [
    {
      name: "society_name",
      value: form.society_name,
      required: true,
      type: "text",
      label: "Name",
      col: 6,
    },
    {
      name: "society_phone",
      value: form.society_phone,
      required: true,
      type: "number",
      label: "Phone",
      col: 6,
    },
    {
      name: "society_type",
      value: form.society_type,
      required: true,
      type: "select",
      options: [
        "Structured Cooperative/Association/Union",
        "Semi-Structured Cooperative/Association/Union",
        "Famers' Cooperative/Association/Union",
      ],
      label: "cooperative/Association/Union type",
      col: 6,
    },
    // {
    //   name: "society_email",
    //   value: form.society_email,
    //   required: true,
    //   type: "email",
    //   label: "Cooperative mail",
    //   col: 6,
    // },
    {
      name: "contribution",
      value: form.contribution,
      required: true,
      label: "Monthly Contribution",
      col: 6,
    },
    {
      name: "society_address",
      value: form.society_address,
      type: "textarea",
      label: "Office Address",
      col: 6,
    },
    {
      name: "society_logo",
      value: form.society_logo,
      type: "file",
      label: "Logo",
      col: 12,
    },
  ];

  const adminDetails = [
    {
      name: "firstname",
      value: form.firstname,
      required: true,
      type: "text",
      label: "First Name",
      col: 6,
    },
    {
      name: "lastname",
      value: form.lastname,
      required: true,
      type: "text",
      label: "Last Name",
      col: 6,
    },
    {
      name: "phone",
      form: form.phone,
      required: true,
      type: "number",
      label: "Phone number",
      col: 6,
    },
    {
      name: "email",
      form: form.email,
      required: true,
      type: "email",
      label: "Email",
      col: 6,
    },
    {
      name: "address",
      form: form.address,
      type: "textarea",
      label: "Home address",
      col: 6,
    },
    {
      name: "password",
      value: form.password,
      required: true,
      type: "password",
      label: "Password",
      col: 6,
    },
  ];

  const history = useHistory();

  const handleChange = ({ target: { value, name, files } }) => {
    if (name === "society_logo") {
      let fileLogo = files[0];
      setLogo(fileLogo);
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      form.society_type === "Organization" &&
      (form.bank_name === "" ||
        form.society_address === "" ||
        form.society_email === "" ||
        form.society_name === "")
    ) {
      alert("Incomplete form");
    }
    if (
      form.name === "" ||
      form.email === "" ||
      form.phone_no === "" ||
      form.password === ""
    ) {
      alert("Please complete the form");
    } else {
      form.query_type = "insert";
      let formData = new FormData();

      Object.keys(form).forEach((element, i) => {
        formData.append(element.toString(), form[element]);
      });
      formData.append("logo", logo);
      // alert(JSON.stringify(formData))
      fetch(`${apiURL}/societies/create-society`, {
        method: "POST",
        body: formData,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   accept: 'application/json',
        // },
      })
        .then((raw) => raw.json())
        .then((resp) => {
          if (resp.success) {
            toast.success(resp.message);
            history.push("/auth/login");
          } else {
            toast.error(resp.message);
          }
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  return (
    <div
    // className={`register ${themeClass}`}
    // style={theme.primary.main}
    >
      <Row>
        <div className="col-md-3 register-left">
          <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
          <h3>Joli</h3>
          <p>
            {" "}
            Essential Commodities Digital Sales and supply Multi purpose
            cooperative society ltd
          </p>
          <input
            type="submit"
            onClick={() => history.push("/auth/login")}
            name=""
            value={`Goto Login`}
          />
          <br />
        </div>
        <div className="col-md-9 register-right">
          <h3 className="text-center">Sign-up as a new society</h3>
          <Row>
            <Form onSubmit={handleSubmit}>
              <Col className="card-bordered" md={6}>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <CardBody className="text-dark">
                      <h4 className={`text-center ${primaryText}`}>
                        Association details
                      </h4>
                      <CustomForm fields={fields} handleChange={handleChange} />
                    </CardBody>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <CardBody className="text-dark">
                      <h4 className={`text-center ${primaryText}`}>
                        Admin details
                      </h4>
                      <CustomForm
                        fields={adminDetails}
                        handleChange={handleChange}
                      />
                      <center>
                        <CustomButton
                          hollow
                          onClick={handleSubmit}
                          className="text-center btn-register"
                        >
                          {" "}
                          {loading && <Spinner size="sm" />}
                          Submit
                        </CustomButton>
                      </center>
                    </CardBody>
                  </div>
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      </Row>
    </div>
  );
};
export default Signup;
