import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody, Col, Form, Row, Spinner } from 'reactstrap'
import { apiURL } from 'variables'
import cover from '../../../images/hands2.jpg'

export default function EmailSent() {
    const history = useHistory()

    return (
        <div className="login-div">
            <Row className="m-0 login-row">
                <Col
                    md={6}
                    style={{
                        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
                    }}
                    className="img-col">
                    <div className="title-wrap">
                        <h3 className="bits-head">BitsCoops</h3>
                        <p>Automated cooperative society system</p>
                        <input
                            type="submit"
                            onClick={() => history.push('/auth/login')}
                            className="goto"
                            name=""
                            value={`Goto Log in`}
                        />
                    </div>
                </Col>
                <Col md={6} className="login-col">
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

                                <Card className="login-card shadow-lg">
                                    <CardBody className="">
                                        <p className="login-text">Email Sent</p>
                                        {/* <p className='m-0 text-secondary' style={{ fontSize: '12px' }}>An email was sent to your inbox with a link to reset your password and get back to your account.</p> */}
                                        <p className='m-0 text-secondary' style={{ fontSize: '12px' }}>Click the link that was sent to your email and change your password.</p>

                                        <div className='text-center'>
                                            <hr style={{ padding: 0 }}></hr>
                                            <p
                                                style={{
                                                    fontSize: '13px',
                                                    marginTop: 5,
                                                    color: 'grey',
                                                }}
                                            >
                                                Already a member?{' '}
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "rgb(96, 14, 230)",
                                                    }}
                                                    onClick={() => history.push("/auth/login")}
                                                >
                                                    Login here!
                                                </span>{' '}
                                            </p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}
