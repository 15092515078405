import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import BackButton from "components/UI/BackButton";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { today } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import { useQuery } from "hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Alert, Badge, Card } from "reactstrap";
import { getUserReports } from "redux/actions/reports";
import { getReports } from "redux/actions/reports";
import { theme } from "variables";
import { CURRENCY } from "variables";

function ReportDetails() {
  const location = useLocation();
  const isMembersDashboard = location.pathname.includes("/member");
  const query = useQuery();
  const report_type = query.get("report_type");
  // const from = query.get("from");
  // const to = query.get("to");
  const dispatch = useDispatch();
  const { society, user } = useSelector((state) => state.auth);
  // const today = moment().format('YYYY-MM-DD')

  const reports = useSelector((state) => state.reports)[report_type] || [];

  const [range, setRange] = useState({
    from: today,
    to: today,
  });

  const handleDateRangeChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));

  useEffect(() => {
    if (isMembersDashboard) {
      dispatch(
        getUserReports({
          query_type: report_type,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user?.id,
        })
      );
    } else {
      dispatch(
        getReports({
          query_type: report_type,
          society_id: society.id,
          from: range.from,
          to: range.to,
        })
      );
    }
  }, [society.id, dispatch, range.from, range.to]);

  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
      ),
    },
    { title: "Member Name", value: "member_name" },
    {
      title: `Amount Paid (${CURRENCY})`,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(
            item.credit.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    { title: "Reference", value: "ref" },
    { title: "Month", value: "payment_month" },
    {
      title: "Year",
      custom: true,
      component: (item) => <div>{item.payment_year}</div>,
    },
    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Badge color={item.status === "success" ? "success" : "warning"}>
            {item.status}
          </Badge>
        </div>
      ),
    },
  ];

  const totalContribution = reports.reduce(
    (a, b) => a + parseFloat(b.credit),
    0
  );
  return (
    <>
      <div className="d-flex flex-direction-row justify-content-between align-items-center">
        <BackButton />
        <CustomButton className="bg-success" disabled>
          Export Data
        </CustomButton>
      </div>
      {/* {JSON.stringify(range)} */}
      <Card body>
        <DaterangeSelector
          from={range.from}
          to={range.to}
          handleChange={handleDateRangeChange}
        />
        <CustomTable
          fields={fields}
          data={reports}
          total={[
            "",
            "Total",
            formatNumber(
              totalContribution.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })
            ),
            "",
            "",
          ]}
        />

        {reports.length ? null : (
          <EmptyList message="Report is empty, check back later." />
        )}
      </Card>
    </>
  );
}

export function EmptyList({ message = "List is empty." }) {
  return (
    <div
      className="text-center rounded pt-3 pb-1"
      style={{ background: theme.primary.main.backgroundColor }}
    >
      <p style={{ color: "white" }}>{message}</p>
    </div>
  );
}

export default ReportDetails;
