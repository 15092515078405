import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import CustomModal from "components/UI/CustomModal";
import Loading from "components/UI/Loading";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Card, CardTitle } from "reactstrap";
import { getImageUrl } from "redux/actions/api";
import { _fetchApi } from "redux/actions/api";
import { EmptyList } from "../Admin/ReportDetails";
import { updateMembership } from "./api";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";

function PendingMembership() {
  const { society, user } = useSelector((state) => state.auth);
  const [pendingList, setPendingList] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const getPendingMembershipRequest = useCallback(() => {
    setFetching(true);
    _fetchApi(
      `/societies/pending-membership/${society.id}`,
      (resp) => {
        setFetching(false);
        if (resp.success) {
          setPendingList(resp.data);
        }
      },
      (e) => {
        setFetching(false);
        console.log(e);
      }
    );
  }, [society.id]);

  useEffect(() => {
    getPendingMembershipRequest();
  }, [getPendingMembershipRequest]);

  const updateMember = (i, status) => {
    setLoading(true);
    updateMembership(
      {
        status,
        user_id: user.id,
        membership_id: i.membership_id,
        society_id: society.id,
      },
      (d) => {
        setLoading(false);
        if (d.success) {
          toast.success(d.message);
        } else {
          toast.error(d.message);
        }
        getPendingMembershipRequest();
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };

  const tableFields = [
    { title: "Application Number", value: "membership_id" },
    {
      title: "Applicant  Picture",
      custom: true,
      component: (i) => (
        <div
          className="text-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setImage(i.profile_pic);
            toggle();
          }}
        >
          <img src={getImageUrl(i.profile_pic)} style={{ height: 40 }} />
        </div>
      ),
    },
    { title: "Applicant Name", value: "fullname" },
    { title: "Applicant Phone", value: "phone" },
    { title: "Applicant Email", value: "email" },
    {
      title: "Action",
      custom: true,
      component: (item, idx) => (
        <div className="text-center">
          <CustomButton
            loading={activeTab === idx && loading}
            size={"sm"}
            onClick={() => {
              setActiveTab(idx);
              updateMember(item, "Approved");
            }}
          >
            Approve
          </CustomButton>
          <CustomButton
            color="danger"
            outlined
            size={"sm"}
            loading={activeTab === idx && loading}
            onClick={() => {
              setActiveTab(idx);
              updateMember(item, "Rejected");
            }}
          >
            Reject
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <Card body>
      <CardTitle className="h4 text-center mb-4">
        Pending Membership Requests
      </CardTitle>
      {fetching && <Loading size="sm" />}
      
      <CustomTable fields={tableFields} data={pendingList} />
      {pendingList.length ? null : (
        <EmptyList message="There are not pending membership request at this time, check back later." />
      )}
      <CustomModal size="sm" isOpen={isOpen} toggle={toggle}>
        <div className="text-center">
          <img
            src={getImageUrl(image)}
            style={{ height: "100%", width: "100%", margin: 0, padding: 0 }}
          />
        </div>
      </CustomModal>
    </Card>
  );
}

export default PendingMembership;
