import { SearchBar } from "components/UI";
import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import Loading from "components/UI/Loading";
import React from "react";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardTitle } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import { theme } from "variables";
import { EmptyList } from "../Admin/ReportDetails";

function Defaulters() {
  const { society } = useSelector((s) => s.auth);
  const [defaulters, setDefaulters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const history = useHistory();
  const getDefaultersList = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/loan-repayment/defaulters/${society.id}?query_type=select`,
      (resp) => {
        setLoading(false);
        if (resp && resp.data && resp.data.length) {
          setDefaulters(resp.data);
        }
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [society.id]);

  useEffect(() => {
    getDefaultersList();
  }, [getDefaultersList]);

  return (
    <Card body>
      <CardTitle className="h4 mt-0 mb-2 text-center">
        Defaulted Members List
      </CardTitle>
      {loading && <Loading />}

      <SearchBar
        placeholder="Search by members..."
        filterText={searchTerm}
        onFilterTextChange={({ target: { value } }) => setSearchTerm(value)}
      />
      {defaulters.length ? (
        <CustomTable
          rowStyle={(i) => ({
            backgroundColor:
              i.no_of_months === 1
                ? "yellow"
                : i.no_of_months === 2
                ? "orange"
                : i.no_of_months >= 3
                ? "red"
                : "",
          })}
          fields={[
            { title: "Member", value: "user_name" },
            { title: "Loan ID", value: "loan_id" },
            { title: "Loan Description", value: "description" },
            {
              title: "Months",
              custom: true,
              className: "text-center",
              component: (i) => <div>{i.no_of_months}</div>,
            },
            {
              title: "Action",
              custom: true,
              component: (i) => (
                <div className="text-center">
                  <Button
                    onClick={() => {
                      history.push(
                        `/admin/loan/my-loan-details?loan_id=${i.loan_id}`
                      );
                    }}
                  >
                    View
                  </Button>
                </div>
              ),
            },

            //   { title: 'Total Amount Collected', value: '' },
            //   { title: 'Total Amount Paid', value: '' },
            //   { title: 'Total Outstanding', value: '' },
          ]}
          data={defaulters}
        />
      ) : (
        <EmptyList message="No data found" />
      )}
    </Card>
  );
}

export default Defaulters;
