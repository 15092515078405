import DaterangeSelector from 'components/UI/DaterangeSelector'
import React from 'react'
import { Button, Card, CardTitle, FormGroup, Input, Label, Row, Table } from 'reactstrap'

export default function ViewAccountReport(
    from = '',
    to = '',
    handleChange = (f) => f,
    noLabel = false,) {
//   const [range, setRange] = useState({ from: startOfYear, to: today });

//   const handleChange = ({ target: { name, value } }) =>
//     setRange((p) => ({ ...p, [name]: value }))
  return (
    <Card body>
        <CardTitle className="h4 text-center mb-4">Account title</CardTitle> 
        <Row>
            <FormGroup className='col-md-3'>
            <Label style={{ fontWeight: 'bold' }}>From:</Label>
            <Input
                className='col-md-12'
                type="date"
                // value={from}
                name="from"
                // onChange={handleChange}
                style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
                }}
            />
            </FormGroup>
            <FormGroup className='col-md-3'>
            <Label style={{ fontWeight: 'bold' }}>To:</Label>
            <Input
                type="date"
                // value={from}
                name="from"
                // onChange={handleChange}
                style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
                }}
            />
            </FormGroup>
            <FormGroup className='col-md-4'>
            <Label style={{ fontWeight: 'bold' }}>Search:</Label>
            <Input
                type="Text"
                // value={from}
                name="from"
                placeholder='Search here'
                // onChange={handleChange}
                style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
                }}
            />
            </FormGroup>
            <Button style={{
                marginTop: 30,
                height: 40,
                cursor: 'pointer'
            }}
                className='col-md-2'
            >
                Export CSV
            </Button>
        </Row>
        <Table bordered>
            <thead>
                <tr>
                    <th>
                        S/N
                    </th>
                    <th>
                        Member
                    </th>
                    <th>
                        Amount
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        Receipt No
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td className="col-md-2">
                        <Button 
                            style={{
                                height: 40,
                                cursor: 'pointer'
                            }}
                            className="col-md-6"
                        >View</Button>
                    </td>
                </tr>
            </tbody>
        </Table>
    </Card>
  )
}
