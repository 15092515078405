import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import CustomCard from "components/UI/CustomCard";
import { _formatNumber } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import { CURRENCY } from "variables";
import { EmptyList } from "../Admin/ReportDetails";
import { getLoanTypesList } from "./api";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";

function LoanTypeList() {
  const history = useHistory();
  const [loanList, setLoanList] = useState([]);

  const getLoanList = () => {
    getLoanTypesList((d) => {
      // alert(JSON.stringify(d))
      setLoanList(d);
    });
  };

  useEffect(() => {
    getLoanList();
  }, []);

  const tableFields = [
    { title: "Loan Type", value: "type" },
    { title: "Title", value: "loan_title" },
    {
      title: `Maximum Amount`,
      custom: true,
      component: (item) => (
        <div>
          {/* {CURRENCY} */}
          {item.max_amount_type?.toLowerCase() === "percentage"
            ? item.max_amount + "% of savings"
            : CURRENCY + " " + _formatNumber(item.max_amount)}
          {/* {formatNumber(item.min_amount)} - {CURRENCY} */}
        </div>
      ),
    },
    {
      title: "Period",
      custom: true,
      component: (item) => (
        <div>
          {item.period_value} {item.period_duration}
        </div>
      ),
    },
  ];

  return (
    <Card body>
      <CardTitle className="h4 text-center mb-4">List of Loans</CardTitle>

      <div className="mb-2">
        <CustomButton
          onClick={() => history.push("/admin/setup-loan-types-form")}
        >
          Setup New Loan
        </CustomButton>
      </div>

      <CustomTable data={loanList} fields={tableFields} />
      {loanList.length ? null : (
        <EmptyList message="You are yet to setup your loans, click the button above to get started." />
      )}
    </Card>
  );
}

export default LoanTypeList;
