import { _fetchApi, _postApi, _updateApi } from "./api";
import types from "./types";

export function getMembers(id, cb = (f) => f, error = (f) => f) {
  return (dispatch) => {
    _fetchApi(
      `/societies/members?society_id=${id}`,
      (res) => {
        if (res.success) {
          dispatch({ type: types.society.MEMBERS, payload: res.data });
          cb(res.data);
        }
      },
      (err) => {
        dispatch({ type: types.ERROR, payload: err });
        error(err);
      }
    );
  };
}

export function getGrantorReqs(id, cb = (f) => f, error = (f) => f) {
  _fetchApi(
    `/grantor-reques/list?grantor_id=${id}`,
    (res) => {
      if (res.success) {
        cb(res.data);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function getMemberView(id, cb = (f) => f, error = (f) => f) {
  _fetchApi(
    `/grantor/member-view?id=${id}`,
    (res) => {
      if (res.success) {
        cb(res.data);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function updateStatus(
  id = 0,
  loan_application_id = "",
  grantor_type = 0,
  cb = (f) => f,
  error = (f) => f
) {
  _updateApi(
    `/grantor/update-status?id=${id}&loan_application_id=${loan_application_id}&grantor_type=${grantor_type}`,
    [],
    (res) => {
      console.log(res)
      if (res.success) {
        cb(res);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function updateApprovalLevel(
  user_id,
  society_id,
  level_of_approval,
  cb = (f) => f,
  error = (f) => f
) {
  _updateApi( 
    `/user/update-approval-level?user_id=${user_id}&society_id=${society_id}&level_of_approval=${level_of_approval}`,
    [],
    (res) => {
      console.log(res)
      console.log("res")
      if (res.success) {
        cb(res);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function rejectStatus(id,loan_id,grantor_type, cb = (f) => f, error = (f) => f) {
  _updateApi(
    `/grantor/reject-status?id=${id}&loan_id=${loan_id}&grantor_type=${grantor_type}`,
    [],
    (res) => {
      if (res.success) {
        cb(res);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function queryDefaulting(data, cb = (f) => f, error = (f) => f) {
  _postApi(
    `/loan-defaulters`,
    data,
    (res) => {
      if (res.success) {
        cb(res.data);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function changeGrantorApi(grantors=[], cb = (f) => f, error = (f) => f) {
  _postApi(
    `/grantor/change-grantor`,
    grantors,
    (res) => {
      if (res.success) {
        cb(res);
      }
    },
    (err) => {
      error(err);
    }
  );
}

export function getGuarantors(
  created_at = "",
  loan_application_id = "",
  cb = (f) => f,
  error = (f) => f
) {
  _fetchApi(
    `/grantor/get-grantors?created_at=${created_at}&loan_application_id=${loan_application_id}`,
    (res) => {
      if (res.success) {
        cb(res.data);
      }
    },
    (err) => {
      error(err);
    }
  );
}
