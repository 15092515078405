import store from 'redux/store'
import { getLoanScheduleByStatus } from 'views/app/loanApplication/api'
import { _fetchApi, _updateApi } from './api'
import types from './types'

const endpoint = 'account'

export function getBankListFW(success = (f) => f, error = (f) => f) {
  _fetchApi(
    `/fw/bank-list`,
    (resp) => {
      // console.log(resp)
      if (resp && resp.data) {
        success(resp.data)
      }
    },
    (err) => {
      error(err)
    },
  )
}

export function getContributionSchedule(
  { from = '', to = '',user_id ='' },
  callback = (f) => f,
) {
  const { user, society } = store.getState().auth

  _fetchApi(
    `/user-reports?query_type=contribution_schedule&society_id=${society.id}&user_id=${user_id}&from=${from}&to=${to}`,
    (resp) => {
      if (resp && resp.data && resp.data.length) {
        callback(resp)
        // dispatch({
        //   type: types.contribution.SET_CONTRIBUTION_SCHEDULE,
        //   payload: resp.data,
        // })
      }
    },
    (err) => {
      console.log(err)
    },
  )
}

export function enableStatus(
  account_head,
  status,
  callback = (f) => f,
  fallback = (f) => f
) {
  const {  society } = store.getState().auth;
  _updateApi(
    `/contribution/enable-status?status=${status}&society_id=${society.id}&account_head=${account_head}`,
  [],
    (resp) => {
      if (resp.success ) {
        callback(resp)
      }
    },
    (err) => {
      console.log(err);
      fallback()
    },
  )
  
}

export function getPendingLoanSchedule(callback = (f) => f,member_id=null,check='') {
  return (dispatch) => {
    const { user, society } = store.getState().auth

    getLoanScheduleByStatus(
      {check, user_id: member_id?member_id:user.id, society_id: society.id },
      (d) => {
        if (d && d.data && d.data.length) {
          let listByLoan = {}
          d.data.forEach((sch) => {
            let loan_id = sch.loan_id
            if (Object.keys(listByLoan).includes(loan_id)) {
              listByLoan[loan_id] = [...listByLoan[loan_id], sch]
            } else {
              listByLoan[loan_id] = [sch]
            }
          })
          // setScheduleData(listByLoan)
          dispatch({
            type: types.loan.SET_LOAN_SCHEDULE_BY_LOAN,
            payload: listByLoan,
          })
          callback(Object.keys(listByLoan)[0], listByLoan)
        }
      },
    )
  }
}
