import React from "react";
import toast from "react-hot-toast";
import { FaLessThan } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Badge } from "reactstrap";
import { getRoleLink } from "views/helper";
import "./AccountSwitch.css";
export default function AccountSwitch() {
  const { societies } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="brain-in">
      <button
        className="log-in-btn"
        onClick={() => {
          localStorage.removeItem("@@token");
          history.push("/");
        }}
      >
        {" "}
        {`< `}go back to login
      </button>
      {societies &&
        societies.map((item) => (
          <div
            className="Div-one"
            onClick={() => {
              if (item.status === "Pending") {
                toast.error("Please the account is not yet approved");
              } else {
                dispatch({ type: "UPDATE_USER", payload: item });
                history.push(`/${item.role.toLowerCase()}/dashboard`);
              }
            }}
          >
            <Link style={{ display: "flex" }} href="">
              {item.society_logo ? (
                <img
                  className="p_"
                  alt="logo"
                  src={item.society_logo}
                  style={{ height: "50px", borderRadius: "50px" }}
                />
              ) : (
                <p className="p">
                  {item.society_name.split(" ")[0].slice(0, 2)}
                </p>
              )}
              <div className="divider">
                <div style={{ fontWeight: "bold" }}>{item.society_name}</div>

                <div
                  style={{
                    margin: 0,
                    fontSize: 12,
                    backgroundColor: "#fff",
                  }}
                >
                  <Badge
                    color={item.status === "Approved" ? "success" : "warning"}
                  >
                    {item.status}
                  </Badge>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
}
