import React from "react";
import { useSelector } from "react-redux";
import ProfileDetails from "./ProfileDetails";
import PasswordDetails from "./PasswordDetails";
import BankDetails from "./BankDetails";
import ProfilePicture from "./ProfilePicture";
import { Col, Row } from "reactstrap";
import EmployerInfo from './EmployerInfo'
import NextOfKin from './NextOfKin'

function Profile() {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <ProfilePicture />
      <ProfileDetails />
      <Row>
        <Col sm={12} md={6} xl={6}>
          <PasswordDetails />
        </Col>
        <Col sm={12} md={6} xl={6}>
          {" "}
          <BankDetails />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} xl={6}>
          <EmployerInfo />
        </Col>
        <Col sm={12} md={6} xl={6}>
          {" "}
          <NextOfKin />
        </Col>
      </Row>
    </>
  );
}

export default Profile;
