import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardTitle,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { useHistory } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import StatusBadge from "components/UI/StatusBadge";
import { EmptyList } from "../Admin/ReportDetails";
import { themeClass } from "variables";
import { getRoleLink } from "views/helper";
import { CURRENCY } from "variables";
import { SummaryItem } from "../withdraw/MyWithdrawals";
import { getGuarantors } from "redux/actions/society";
import CustomModal from "components/UI/CustomModal";
import { useDispatch } from "react-redux";
import { getMembers } from "redux/actions/society";
import { changeGrantorApi } from "redux/actions/society";
function MyLoans() {
  const history = useHistory();
  const [guarantors, setGuarantors] = useState([]);
  const society = useSelector((state) => state.auth.society);
  // const { members } = useSelector((state) => state.society);
  const [members, setMembers] = useState([]);
  const [applicationId, setApplicationId] = useState();
  const user = useSelector((state) => state.auth.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const _getMembers = useCallback(() => {
    dispatch(getMembers(society.id));
  }, [society.id]);
  const handleSubmitChanges = (grantors) => {
    setLoading(true);
    changeGrantorApi(
      grantors,
      (res) => {
        toast.success(res.message);
        setLoading(false);
        toggle();
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      }
    );
  };

  const getMyLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/my-loans/${user.id}/${society.id}`,
      (d) => {
        if (d && d.data) {
          setList(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [user.id, society.id]);
  const _getGuarantors = useCallback(
    (created_at = "", loan_application_id = "") => {
      getGuarantors(
        moment(created_at).format("YYYY-MM-DD"),
        loan_application_id,
        (res) => {
          const arr = [];
          res.forEach((item) => {
            arr.push({
              ...item,
              enabled: false,
              check: "",
              society_id: society.id,
            });
          });
          setGuarantors(arr);
          toggle();
        },
        () => {
          toast.error("Error Occured");
        }
      );
    },
    []
  );
  const onChangeGuarantor = (idx) => {
    let arr = [];
    guarantors.length &&
      guarantors.forEach((item, index) => {
        if (idx === index) {
          arr.push({ ...item, enabled: true });
        } else {
          arr.push(item);
        }
      });
    setGuarantors(arr);
  };
  const onSelectChangeGuarantor = (idx, value) => {
    let arr = [];
    guarantors.length &&
      guarantors.forEach((item, index) => {
        if (idx === index) {
          arr.push({ ...item, grantor_id: value, check: "change" });
        } else {
          arr.push(item);
        }
      });
    setGuarantors(arr);
  };
  const searchGrantor = useCallback(() => {
    _fetchApi(
      `/grantor/searching-grantor?loan_application_id=${applicationId}&society_id=${society.id}`,
      (d) => {
        if (d.success) {
          setMembers(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society.id]);
  useEffect(() => {
    getMyLoanList();
    _getMembers();
  }, [getMyLoanList, _getMembers]);
  useEffect(() => {
    searchGrantor();
  }, [searchGrantor, applicationId]);

  const tableFields = [
    { title: "Loan ID", value: "loan_id" },
    {
      title: "Application Date",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {moment(i.created_at).format("YYYY-MM-DD")}
        </div>
      ),
    },
    {
      title: "Loan Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <StatusBadge status={i.status} />
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            className="mr-1"
            color={themeClass.split("-")[1]}
            onClick={() =>
              history.push(
                getRoleLink(`/loan/my-loan-details?loan_id=${i.loan_id}`)
              )
            }
          >
            View Details
          </CustomButton>
          <CustomButton
            size="sm"
            onClick={() => {
              _getGuarantors(i.created_at, i.loan_id);
              setApplicationId(i.loan_id);
            }}
            disabled={i.guarantor_count >= 2 ? true : false}
          >
            Change Guarantor
          </CustomButton>
          {/* <CustomButton size="sm" color="danger" onClick={() => {}}>
            Cancel
          </CustomButton> */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getMyLoanList();
    _getMembers();
  }, [getMyLoanList, _getMembers]);
  return (
    <>
      <Card body>
        <Row className="">
          <SummaryItem
            label="Total Loan Collected"
            _label='Total Normal Loan Collected'
            value={reports["Total Loan Collected"]?.total_withdrawal}
            society={society}
            user={user}
          />
          <SummaryItem
            label="Loan Repayment Balance"
            value={reports["Loan Repayment Balance"]?.total_withdrawal}
            society={society}
            user={user}
          />
          <SummaryItem
            label="Total Special Loans"
            value={reports["Total Special Loans"]?.total_withdrawal}
            society={society}
            user={user}
          />
          <SummaryItem
            label="Special Repayment Balance"
            value={reports["Special Repayment Balance"]?.total_withdrawal}
            society={society}
            user={user}
          />
        </Row>

        {/* <CardTitle className="h4 text-center mb-2">My Loans</CardTitle> */}

        <div className="mb-2">
          <CustomButton
            onClick={() =>
              history.push(getRoleLink("/loan/new-loan-application"))
            }
          >
            Start New Loan Application
          </CustomButton>
        </div>
        {/* {JSON.stringify(list)} */}
        <CustomTable fields={tableFields} data={list} size="sm" />

        {list.length ? null : (
          <EmptyList message="You are yet to request for a loan, click the button above to get started." />
        )}
      </Card>
      <ChangeGuarantorModal
        header={"Change Guarantor"}
        isOpen={isOpen}
        toggle={toggle}
        data={guarantors}
        onChangeGuarantor={onChangeGuarantor}
        members={members}
        user={user}
        onSelectChangeGuarantor={onSelectChangeGuarantor}
        loading={loading}
        handleSubmitChanges={handleSubmitChanges}
      />
      <Toaster />
    </>
  );
}

const ChangeGuarantorModal = ({
  header = "",
  isOpen = false,
  toggle = (f) => f,
  data = [],
  onChangeGuarantor = (f) => f,
  members = [],
  user = {},
  onSelectChangeGuarantor = (f) => f,
  loading = false,
  handleSubmitChanges = (f) => f,
}) => {
  const fields = [
    {
      title: "Loan Id ",
      custom: true,
      component: (i) => <div className="">{i.loan_application_id}</div>,
    },
    {
      title: " Application Date",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {moment(i.created_at).format("YYYY-MM-DD")}
        </div>
      ),
    },
    {
      title: "Member Name",
      custom: true,
      component: (i, index) => (
        <div className="text-left">
          {i.enabled ? (
            <FormGroup>
              <select
                className={`form-control`}
                onChange={({ target: { value } }) => {
                  onSelectChangeGuarantor(index, value);
                }}
              >
                <option>--select---</option>
                {members
                  ?.filter((a) => a.user_id !== user.id)
                  // ?.filter((a) => a.user_id !== i.grantor_id)
                  ?.map((item, index) => (
                    <option key={index} value={item.user_id}>
                      {`${item.firstname} ${item.lastname}`}
                    </option>
                  ))}
              </select>
            </FormGroup>
          ) : (
            i.member_name
          )}
        </div>
      ),
    },
    {
      title: `Loan Amount ${CURRENCY}`,
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.loan_amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (i, ind) => (
        <div className="text-left">
          <CustomButton
            size="sm"
            disabled={i.status === "Approve" ? true : false}
            onClick={() => {
              onChangeGuarantor(ind);
            }}
          >
            Change
          </CustomButton>
        </div>
      ),
    },
  ];
  return (
    <>
      <CustomModal
        header={header}
        isOpen={isOpen}
        toggle={toggle}
        footerButton={
          <div className="m-2">
            <CustomButton outline color="danger" onClick={toggle}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={!data.filter((item) => item.check !== "").length}
              loading={loading}
              onClick={() => {
                handleSubmitChanges(
                  data.filter((item) => item.check === "change")
                );
              }}
            >
              Submit
            </CustomButton>
          </div>
        }
      >
        <CustomTable data={data} fields={fields} />
      </CustomModal>
    </>
  );
};

export default memo(MyLoans);
