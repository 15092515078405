import { CustomButton } from "components/UI";
import DaterangeSelector from "components/UI/DaterangeSelector";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ButtonGroup } from "reactstrap";
import { getRequest } from "./reqHelper";
import { useSelector } from "react-redux";
import { today } from "utils/helpers";
import moment from "moment";
import Loading from "components/UI/Loading";
import { CustomTable } from "components/UI";
import { getRoleLink } from "views/helper";
import { EmptyList } from "../../Admin/ReportDetails";
import { getItemFromJoli } from "redux/actions/auth";

export default function Request() {
  const goto = useHistory();
  const { society, user } = useSelector((state) => state.auth);
  const location = useLocation();

  const _from = moment(today).subtract(6, "day").format("YYYY-MM-DD");
  const admin = location?.pathname?.includes("/admin/");
  const role = society.role;
  const userRole = admin && role == "Admin";
  const [tab, setTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const [form, setForm] = useState({
    from: _from,
    to: today,
    member_id: userRole ? "" : user.id,
    req_status: "pending",
    id: 0,
  });

  const [qType, setQType] = useState("select");

  const handleRange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const _getRequest = useCallback(() => {
    getRequest(form, qType, society.id, (d) => {
      setData(d?.data);
    });
  }, [qType, society.id, form]);

  useEffect(() => {
    _getRequest();
    
  }, [_getRequest]);

  const tableFields = [
    {
      title: "S/N",
      custom: true,
      component: (item, i) => <div>{i + 1}</div>,
    },
    {
      title: "Request ID",
      value: "req_no",
    },
    { title: "Member Name", value: "member_name" },
    { title: "Submitted at", value: "created_at" },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div>
          <CustomButton
            onClick={() =>
              goto.push(
                getRoleLink(
                  `/view-request?req_no=${item.req_no}&req_status=${form.req_status}`
                )
              )
            }
          >
            View
          </CustomButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      {loading && <Loading />}
      {/* {JSON.stringify(userRole)} */}
      <div className="d-flex justify-content-between">
        <CustomButton onClick={() => goto.push("/admin/new-requests/new")}>
          Add new request
        </CustomButton>
        <div className="mr-2">
          <ButtonGroup>
            <CustomButton
              className="mr-0"
              outline={tab !== 1 ? true : false}
              onClick={() => {
                setTab(1);
                setForm((p) => ({ ...p, req_status: "pending" }));
              }}
            >
              Pending
            </CustomButton>
            <CustomButton
              className="ml-0 mr-0"
              outline={tab !== 2 ? true : false}
              onClick={() => {
                setTab(2);
                setForm((p) => ({ ...p, req_status: "approved" }));
              }}
            >
              Approved
            </CustomButton>
            <CustomButton
              className="ml-0"
              outline={tab !== 3 ? true : false}
              onClick={() => {
                setTab(3);
                setForm((p) => ({ ...p, req_status: "delivered" }));
              }}
            >
              Delivered
            </CustomButton>
          </ButtonGroup>
        </div>
      </div>
      <div>
        <DaterangeSelector
          from={form.from}
          to={form.to}
          handleChange={handleRange}
        />
      </div>
      {/* {JSON.stringify({ qType, data, society })} */}
      <CustomTable fields={tableFields} data={data} bordered />
      {data.length ? null : (
        <EmptyList message="Report is empty, check back later or change the date range." />
      )}
    </div>
  );
}
