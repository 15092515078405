import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap'
import moment from 'moment'
import { _fetchApi } from 'redux/actions/api'
import { useSelector } from 'react-redux'
import useQuery from 'helpers/useQuery'
import { getLoanSchedule } from './api'
import ScheduleCalendar from 'components/UI/ScheduleCalendar'

function RepaymentSchedule() {
  const query = useQuery()
  const loan_id = query.get('loan_id')
  const society = useSelector((state) => state.auth.society)
  const user = useSelector((state) => state.auth.user)
  const [scheduleData, setScheduleData] = useState({})

  useEffect(() => {
    getLoanSchedule({ loan_id, society_id: society.id }, (d) => {
      if (d && d.data && d.data.length) {
        let listByYear = {}
        d.data.forEach((sch) => {
          let scheduleYear = moment(sch.schedule_date).format('YYYY')
          if (Object.keys(listByYear).includes(scheduleYear)) {
            listByYear[scheduleYear] = [...listByYear[scheduleYear], sch]
          } else {
            listByYear[scheduleYear] = [sch]
          }
        })
        setScheduleData(listByYear)
      }
    })
  }, [loan_id, society.id])

  if (scheduleData && Object.keys(scheduleData).length) {
    return (
      <Card body>
        <CardTitle>Repayment Schedule</CardTitle>
        <ScheduleCalendar data={scheduleData} />
      </Card>
    )
  } else return null
}

export default RepaymentSchedule
