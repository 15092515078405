import { CustomTable } from "components/UI";
import { SelectInput } from "components/UI";
import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import CustomCard from "components/UI/CustomCard";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { getItemFromJoli } from "redux/actions/auth";
import { getPurchaseItem } from "redux/actions/inventory";
import { _warningNotify } from "utils/helpers";
import { _customNotify } from "utils/helpers";
import { formatNumber } from "utils/helpers";
import { uniqueNum } from "variables";

export default function ItemRequest() {
  const [form, setForm] = useState({ item_name: "", req_qty: 1 });
  const [data, setData] = useState([]);
  const [joli, setJoli] = useState([]);
  const [tab, setTab] = useState(1);

  const dispatch = useDispatch();
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const { purchaseItems } = useSelector((state) => state.inventory);
  const { user, society } = useSelector((state) => state.auth);

  const itemRef = useRef();
  const _getPurchaseItem = useCallback(() => {
    dispatch(getPurchaseItem(society.id, 0, 900, () => {}, form.query_type));
  }, [dispatch]);

  useEffect(() => {
    _getPurchaseItem();
    getItemFromJoli(setJoli);
  }, [_getPurchaseItem]);

  const handleDelete = (index) => {
    let final = data.filter((it, id) => id !== index);
    setData(final);
  };
  const handleAdd = () => {
    if (form.req_qty === 0) {
      alert("Invalid form");
    } else {
      setData((p) => [...p, { ...form }]);
      setForm((p) => ({ item_name: "", req_qty: 0 }));
    }
  };

  const handleSubmit = () => {
    let _data = {
      data,
      member_id: user.id,
      member_name: `${user.firstname} ${user.lastname}`,
      society_id: society.id,
      req_no: uniqueNum,
    };
    _postApi(
      "/inventory/v1/post-request",
      _data,
      (e) => {
        _customNotify(e.message);
        setData([]);
      },
      (e) => {
        _warningNotify(e.message);
      }
    );
  };

  const _data = tab === 1 ? joli : purchaseItems;

  const fields = [
    {
      type: "custom",
      component: () => (
        <>
          <SelectInput
            container="col-md-12"
            label={"Select Item Name"}
            options={_data.map((a) => (tab === 1 ? a?.item_name : a.drug_name))}
            name="item_name"
            onChange={({ target: { value } }) => {
              let selected = _data.find((a) => {
                let _val = tab === 1 ? a?.item_name : a.drug_name;
                return _val === value;
              });
              setForm((p) => ({
                ...p,
                ...selected,
                item_name: tab === 1 ? selected?.item_name : selected.drug_name,
                selling_price:
                  tab === 1 ? selected?.unit_price : selected.selling_price,
              }));
            }}
            // value={form.item_name}
          />
        </>
      ),
      col: 6,
    },
    {
      label: "Quantity",
      type: "number",
      name: "req_qty",
      value: form.req_qty,
      required: true,
      col: 3,
    },
  ];

  const _fields = [
    {
      title: "Item Name",
      value: "item_name",
    },
    {
      title: "Quantity",
      custom: true,
      component: (item) => <div>{parseInt(item.req_qty)}</div>,
    },
    {
      title: "Price",
      custom: true,
      component: (item) => (
        <div className="text-right">{formatNumber(item.selling_price)}</div>
      ),
    },
    {
      title: "Total",
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(parseInt(item.selling_price) * parseInt(item.req_qty))}
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item, i) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            color={"danger"}
            outline
            onClick={() => handleDelete(i)}
          >
            <AiFillDelete />
          </CustomButton>
        </div>
      ),
    },
  ];
  const total = data.reduce(
    (it, id) => it + parseInt(id.selling_price) * parseInt(id.req_qty),
    0
  );
  return (
    <>
      <BackButton />
      <CustomCard>
        {/* {JSON.stringify(_data)} */}
        <div>
          <div className="mr-2 text-right">
            <ButtonGroup>
              <CustomButton
                className="mr-0"
                outline={tab !== 1 ? true : false}
                onClick={() => {
                  setTab(1);
                  setData([]);
                  setForm((p) => ({
                    ...p,
                    req_status: "pending",
                    platform: "joli",
                  }));
                }}
              >
                JOLI Inventory
              </CustomButton>
              <CustomButton
                className="ml-0 mr-0"
                outline={tab !== 2 ? true : false}
                onClick={() => {
                  setData([]);
                  setTab(2);
                  setForm((p) => ({ ...p, req_status: "approved" }));
                }}
              >
                My Cooperative Inventory
              </CustomButton>
            </ButtonGroup>
          </div>
          <CustomForm fields={fields} handleChange={handleChange} />
          <div
            className="d-flex shadow p-2 m-2 justify-content-between"
            style={{
              borderLeftColor: "#00f",
              borderRightColor: "#00f",
              borderLeftWidth: 2,
              borderRightWidth: 2,
            }}
          >
            <div>Available Quantity: {form.balance || 0}</div>
            <div>Selling Price: {form.selling_price || 0}</div>
            <div></div>
          </div>
          <center>
            <CustomButton outline onClick={handleAdd}>
              Add to list
            </CustomButton>
          </center>
          <div className="text-right">
            Total: <b>{formatNumber(total)}</b>
          </div>

          <CustomTable size="sm" bordered fields={_fields} data={data} />
        </div>

        {data.length && (
          <center>
            <CustomButton outline onClick={handleSubmit}>
              Submit
            </CustomButton>
          </center>
        )}
      </CustomCard>
    </>
  );
}
