import React, { useState } from 'react'
import ChartistGraph from 'react-chartist'
import moment from 'moment'
// react-bootstrap components
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import CustomStatCard from 'components/UI/CustomStatCard'
import CustomBarChart from 'components/UI/CustomBarChart'
import DaterangeSelector from 'components/UI/DaterangeSelector'
import { CustomButton } from 'components/UI'
import ContributionSchedule from './app/monthlyContribution/ContributionSchedule'
import CustomLoanStatCard from 'components/UI/CustomLoanStatCard'

const scrollView = (id) => {
  document.getElementById(id).scrollIntoView()
}

function Dashboard() {
  const today = moment().format('YYYY-MM-DD')
  // const startOfYear = moment().startOf('y').format('YYYY-MM-DD')
  const startOfYear = "2000-01-01";
  const [range, setRange] = useState({ from: startOfYear, to: today })

  const handleChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }))
  return (
    <>
      <Container fluid>
        {/* <DaterangeSelector
          noLabel
          from={range.from}
          to={range.to}
          handleChange={handleChange}
        /> */}
        <Row id="top-cards">
          <CustomStatCard
            card_target="scrol-1"
            _title="Ordinary Savings"
            title="Total Contribution "
            card_font="fas fa-money-bill-alt"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-1')}
            range={range}
          />
          <CustomLoanStatCard
            card_target="scrol-2"
            title="Loans"
            card_font="fas fa-money-bill-wave"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-2')}
            range={range}
          />
          <CustomStatCard
            card_target="scrol-3"
            title="Special loans"
            card_font="fas fa-hand-holding-usd"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-3')}
            range={range}
          />
          <CustomStatCard
            card_target="scrol-4"
            _title='Special Savings'
            title="Special contribution"
            card_font="fas fa-wallet"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-4')}
            range={range}
          />
           <CustomStatCard
            card_target="scrol-4"
            _title='Shares Contributions'
            title="Shares Contributions"
            card_font="fas fa-wallet"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-4')}
            range={range}
          />
           <CustomStatCard
            card_target="scrol-4"
            _title='Others Contributions'
            title="Others Contributions"
            card_font="fas fa-wallet"
            card_class="info"
            data={[
              { key: 'Amount', val: '10,000.00' },
              { key: 'Members', val: '15' },
            ]}
            link="Navigate"
            action={() => scrollView('scrol-4')}
            range={range}
          />
        </Row>

        <ContributionSchedule />

        {/* Chart starts here */}
        <CustomBarChart
          card_id="scrol-1"
          title="Total Contribution"
          sub_title="Contribution Chart"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          action={() => scrollView('top-cards')}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-2')}
            >
              Next
            </CustomButton>
          }
          range={range}
        />
        <CustomBarChart
          card_id="scrol-2"
          title="Loan & loan repayment"
          sub_title="Loan transactions"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-3')}
            >
              Next
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-3"
          title="Special loan"
          sub_title="Special loan chart"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-4')}
            >
              Next
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-4"
          title="Special contribution"
          sub_title="Special contribution chart"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-3')}
            >
              Prev
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        />
      </Container>
    </>
  )
}

export default Dashboard
