import React, { useCallback, useEffect, useState } from 'react'
import CustomTable from 'components/UI/CustomTable'
import { Button, Card, CardBody, CardTitle, Col } from 'reactstrap'
import { SearchBar, CustomButton } from 'components/UI'
import { themeClass } from 'variables'
import { useHistory } from 'react-router'
import { _fetchApi } from 'redux/actions/api'
import CustomCard from 'components/UI/CustomCard'
import { useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { EmptyList } from '../Admin/ReportDetails'
import { formatNumber } from 'components/UI/helpers'
import moment from 'moment'
import CustomButtonAdmin from 'components/UI/CustomButtonAdmin'

export default function LoanDisbursement() {
  const history = useHistory()
  const society = useSelector((state) => state.auth.society)

  const [pendingLoanList, setPendingLoanList] = useState([])

  const getPendingLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/by-status/Approved/${society.id}`,
      (d) => {
        if (d && d.data) {
          setPendingLoanList(d.data)
        }
      },
      (e) => {
        console.log(e)
      },
    )
  }, [society])

  useEffect(() => {
    getPendingLoanList()
  }, [getPendingLoanList])

  const fields = [
    {
      title: 'Application Date',
      custom: true,
      component: (i) => <div>{moment(i.created_at).format('YYYY-MM-DD')}</div>,
    },
    { title: 'Loan No', value: 'loan_id' },
    { title: 'Applicant Name', value: 'member_name' },
    {
      title: 'Approved Amount',
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
    },
    {
      title: 'Total Interest',
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.total_interest.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
    },
    {
      title: 'Total Admin Charges',
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.total_admin_charges.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
    },
    {
      title: 'Action',
      custom: true,
      component: (i) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            color="info"
            className="mr-1"
            onClick={() =>
              history.push('/admin/loan_disbursement_form?loan_id=' + i.loan_id)
            }
          >
            VIEW DETAILS
          </CustomButton>
          {/* <Button size='sm' color='success' className='mr-1'
            onClick={() => approveLoan(i)}
          >
            APPROVE
          </Button>
          <Button size='sm' color='danger'
            onClick={() => rejectLoan(i)}
          >
            REJECT
          </Button> */}
        </div>
      ),
    },
  ]
  
  return (
    <>
      <Card body>
        <CardTitle className="text-center h4 mb-2">
          Pending Disbursements
        </CardTitle>
        <SearchBar placeholder="Search for a member" className="mt-1" />
        <CustomTable fields={fields} data={pendingLoanList} size="sm" />
        {pendingLoanList.length ? null : (
          <EmptyList message="There are no pending disbursement, check back later." />
        )}
      </Card>
      <Toaster />
    </>
  )
}
